.modal-dialog {
    &.confirmation-modal {
        float: left;
        width: 100%;
        padding: 0;
        max-width: 100%;
        margin: 0;
        overflow: auto;
        height: auto;
        .modal-content {
            border-radius: 20px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            max-width: 700px;
            margin-top: 120px;
            width: auto;
            margin: 120px auto 0;
            .modal-header {
                border: none;
                padding: 20px 22px 0;
                align-items: flex-start;
            }
            .modal-body {
                .modal-messages {
                    float: left;
                    width: 100%;
                    text-align: center;
                    label {
                        float: left;
                        width: 100%;
                        margin: 0 0 35px;
                        img {
                            width: 100px;
                        }
                    }
                    span {
                        font-size: 28px;
                        font-weight: 400;
                        color: #707070;
                        line-height: 36px;
                        padding: 0 100px;
                        box-sizing: border-box;
                        float: left;
                        width: 100%;
                    }
                }
            }
            .modal-footer {
                justify-content: center;
                border: none;
                padding: 20px 40px 40px;
                .btnSmall {
                    color: #7FB541;
                    font-size: 1.3rem;
                    letter-spacing: 0;
                    line-height: 25px;
                    text-align: center;
                    display: inline-block;
                    padding: 5px 20px;
                    height: 58px;
                    background: #fff;
                    border: 2px solid #7FB541;
                    border-radius: 35px;
                    min-width: 150px;
                    &:hover,
                    &.active {
                        background: #7FB541;
                        color: #fff;
                    }
                    &.active {
                        &:focus {
                            box-shadow: none;
                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                        }
                    }
                }
            }
        }
        &.replacementTagging-modal{
            .modal-content{
                max-width:90%; 
                margin: 30px auto 0;
                @media screen and (max-width: 1440px){
                    margin:30px auto 0;
                }
                @media screen and (max-width: 1366px){
                    margin:30px auto 0;
                }
                .modal-header{
                    h3{
                        color: #262726;
                        font-size: 20px;
                        font-weight: bold;
                        letter-spacing: 0;
                        display:inline-block;
                        margin-bottom: 0px;
                        line-height: 26px;
                    }    
                }
                .modal-body{
                    padding:0 25px 25px;
                    .replacementTagging-container{
                        float:left;
                        width:100%;
                        margin-top:20px;
                        .replacementTagging-list{
                            float:left;
                            width:100%;
                            li{
                                float:left;
                                width:48%; 
                                margin-bottom:20px; 
                                margin-right:4%;
                                position:relative;    
                                &:last-child{
                                    margin-right:0px;
                                }
                                .mergeCol{
                                    width:48%;
                                    margin-right:4%;
                                    float: left;
                                    &:last-child{
                                        margin-right:0%; 
                                    }         
                                }                                    
                                label{
                                    float: left;
                                    width: 100%;
                                    font-weight: 700;
                                    color: #464646;
                                    font-family: "PT Sans";
                                    font-size: 16px;
                                    letter-spacing: 0;
                                    line-height: 20px;
                                    text-align: left;
                                    width: 100%;
                                    margin-bottom: 4px; 
                                }
                                textarea{
                                    color: #6C7789;
                                    height: 46px;
                                    padding: 12px 10px 0px 10px;
                                    font-weight: 400;  
                                }
                                .css-1s2u09g-control{
                                    height:auto!important;
                                }
                                .css-1s2u09g-control, .css-1pahdxg-control{
                                    height:auto!important;
                                }
                                .css-ackcql{
                                    input{
                                        height:auto;
                                    }
                                }
                                .css-6j8wv5-Input{ 
                                    input{
                                        height:auto;
                                    }
                                } 
                                .error{
                                        margin: 0;
                                    color: red;
                                    font-weight: 500;
                                    position: absolute;
                                    bottom: -18px;
                                    font-size: 12px;
                                } 
                                &.btnSection{
                                    padding:0px;
                                    text-align:center;  
                                    .btnSmall{
                                        color: #7FB541;
                                        border:2px solid #7FB541;  
                                        font-size: 1rem;
                                        letter-spacing: 0;
                                        line-height: 27px;
                                        text-align: center;
                                        display: inline-block;
                                        padding: 5px 20px;
                                        height: 40px;                                        
                                        background: #fff;
                                        margin-right: 10px;
                                        &.active{
                                            background: #7FB541;
                                            color:#fff;   
                                        }
                                    }
                                } 
                            }
                        }
                        .additive-Full-sec{
                            float:left;
                            width:100%;
                            .additive-col-new{
                                float:left;
                                margin-bottom:25px;
                                width:100%;                            
                                .product-title-header{
                                    display: flex;
                                    justify-content: space-between;      
                                    margin-bottom: 10px;
                                    .product-title{
                                        color: #262726;
                                        font-size: 20px;
                                        font-weight: bold;
                                        letter-spacing: 0;
                                        margin-bottom: 0px;
                                        line-height: 26px;
                                        .addBtn {
                                            display: inline-block;
                                            span {
                                                img {
                                                    @media screen and (max-width:1440px) {
                                                        width:32px;   
                                                    }
                                                    &.imgFirst {
                                                        display: inline-block;
                                                        opacity: 1;
                                                    }
                                                    &.imghover {
                                                        display: none;
                                                        opacity: 0;
                                                    }
                                                }
                                            }
                                            &:hover { 
                                                span {
                                                    img {
                                                        &.imgFirst {
                                                            display: none;
                                                            opacity: 0;
                                                        }
                                                        &.imghover {
                                                            display: inline-block;
                                                            opacity: 1;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }                      
                                    .add-col{
                                        padding:0px;
                                        a{
                                            font-size: 13px;
                                            border: 1px solid #ccc;
                                            padding:6px 14px;
                                            border-radius: 5px; 
                                            display:inline-block; 
                                            &:hover{
                                                border-color:#7FB541;
                                                color:#7FB541;         
                                            }
                                        }    
                                    }
                                }    
                                .newCol-Full{
                                    float:left;
                                    width:100%;
                                    li{
                                        float: left;
                                        width: 100%;                                                                                
                                        position: relative;                                        
                                        .close-header{
                                           position: absolute;
                                            right:2px;
                                            top:3px; 
                                            button{
                                                font-size:12px;  
                                                padding:0px;   
                                            }
                                        }  
                                        .mergeCol{
                                            width:48%;
                                            margin-right:4%;
                                            float: left;
                                            &:last-child{
                                                margin-right:0%;
                                            }         
                                        }
                                        .main-block{
                                            width:48%;
                                            margin-right:4%;
                                            float: left;
                                            &:last-child{
                                                margin-right:0%;
                                            }                                           
                                            .block-1{
                                                float: left;
                                                width: 48%;
                                                margin-right: 4%;
                                                margin-bottom: 25px;
                                                position:relative;
                                                &:last-child{
                                                    margin-right:0px;
                                                }
                                            }
                                            
                                            .block-3{
                                                float:right;
                                                width: 100%;
                                                margin-right:0%;
                                            } 
                                        }                                       
                                        label{
                                            float: left;
                                            width: 100%;
                                            font-weight: 500;
                                            color: #73788E;
                                            font-family: "PT Sans";
                                            font-size: 14px;
                                            letter-spacing: 0;
                                            line-height: 20px;
                                            text-align: left;
                                            width: 100%;
                                            margin-bottom: 4px; 
                                        }
                                        textarea{
                                            color: #6C7789;
                                            height: 46px;
                                            padding: 12px 10px 0px 10px;
                                            font-weight: 400;     
                                        }
                                        .css-1s2u09g-control{
                                            height:auto!important;
                                        }
                                        .css-1s2u09g-control, .css-1pahdxg-control{
                                            height:auto!important;
                                        }
                                        .css-ackcql{
                                            input{
                                                height:auto;
                                            }
                                        }
                                        .css-6j8wv5-Input{ 
                                            input{
                                                height:auto;
                                            }
                                        } 
                                        .error{
                                                margin: 0;
                                            color: red;
                                            font-weight: 500;
                                            position: absolute;
                                            bottom: -18px;
                                            font-size: 12px;
                                        } 
                                        &.btnSection{
                                            padding:0px;
                                            text-align:center;  
                                            .btnSmall{
                                                color: #7FB541;
                                                border:2px solid #7FB541;  
                                                font-size: 1rem;
                                                letter-spacing: 0;
                                                line-height: 27px;
                                                text-align: center;
                                                display: inline-block;
                                                padding: 5px 20px;
                                                height: 40px;                                        
                                                background: #fff;
                                                margin-right: 10px;
                                                &.active{
                                                    background: #7FB541;
                                                    color:#fff;   
                                                }
                                            }
                                        } 
                                    }
                                }
                            }    
                        }    
                    }  
                    .create_Replacement_Group{
                        float:left;
                        width:100%;
                        margin-top: 10px;
                        .create_Replacement-header{
                            float:left;
                            width:100%;
                            display: flex;
                            justify-content: space-between;
                            .col-Top-left{
                                padding:0px;                          
                                .fmtBold{
                                    float: left;
                                    width: 100%;
                                    font-weight: 500;
                                    color: #73788E;
                                    font-family: "PT Sans";
                                    font-size: 14px;
                                    letter-spacing: 0;
                                    line-height: 20px;
                                    text-align: left;
                                    width: 100%;
                                    margin-bottom: 4px; 
                                }
                                .create-groupName{
                                    float:left;
                                    width:100%;  
                                    input[type="text"]{
                                        max-width:350px;
                                        height:40px;   
                                        padding: 5px 10px; 
                                        color:#707070;
                                        font-weight:500;
                                        &:focus{
                                            border-color:#E9E9E9;
                                            color:#707070;
                                            font-weight:500;
                                        }    
                                    }    
                                    .error-msz{
                                        margin: 5px 0 0 0;
                                        color: red;
                                        font-weight: 500;
                                        position: static; 
                                    }                                                          
                                } 
                            } 
                            .col-Top-right{
                                padding:0px; 
                                .btnSmall {
                                    color: #7FB541;
                                    font-size: 1.3rem;
                                    letter-spacing: 0;
                                    line-height: 25px;
                                    text-align: center;
                                    display: inline-block;
                                    padding: 5px 20px;
                                    height: 58px;
                                    background: #fff;
                                    border: 2px solid #7FB541;
                                    border-radius: 35px;
                                    min-width: 150px;
                                    &:hover,
                                    &.active {
                                        background: #7FB541;
                                        color: #fff;
                                    }
                                    &.active {
                                        &:focus {
                                            box-shadow: none;
                                            -webkit-box-shadow: none;
                                            -moz-box-shadow: none;
                                        }
                                    }
                                }
                            }          
                        } 
                        .create_Replacement-form{
                            float:left;
                            width:100%;
                            margin:20px 0px 0;
                            ul{
                                li{
                                    margin-bottom:15px;
                                    float:left;
                                    width:100%;
                                    .fmtBold{
                                        float: left;
                                        width: 100%;
                                        font-weight: 500;
                                        color: #73788E;
                                        font-family: "PT Sans";
                                        font-size: 14px;
                                        letter-spacing: 0;
                                        line-height: 20px;
                                        text-align: left;
                                        width: 100%;
                                        margin-bottom: 4px; 
                                    }
                                    .create-groupName{
                                        float:left;
                                        width:100%;  
                                        input[type="text"]{
                                            max-width:350px;
                                            height:40px;   
                                            padding: 5px 10px; 
                                            color:#707070;
                                            font-weight:500;
                                            &:focus{
                                                border-color:#E9E9E9;
                                                color:#707070;
                                                font-weight:500;
                                            }    
                                        }    
                                        .error-msz{
                                            margin: 5px 0 0 0;
                                            color: red;
                                            font-weight: 500;
                                            position: static; 
                                        }                                                          
                                    }
                                }
                            }                                
                        } 
                        .create_Replacement_Table{
                            float:left;
                            width:100%;  
                            margin-top: 20px;    
                            height: 370px;
                            overflow: auto;
                            @media screen and (max-width: 1366px){
                                height: 300px;
                            }
                            .table{
                                table-layout: fixed;
                                tr{
                                    th{
                                        &:nth-child(6){
                                            width:300px;
                                        } 
                                    }
                                    td{
                                        &:first-child{
                                            text-align:left;
                                        }
                                        &:last-child{
                                            width:150px;
                                        }
                                        &:nth-child(6){
                                            width:300px;
                                        }
                                        div {
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            padding-right: 15px;
                                            @media screen and (max-width:1366px) {
                                                padding-right: 10px;
                                            }
                                        }
                                        input[type="text"]{
                                            height:40px;   
                                            padding: 5px 10px; 
                                            color:#707070;
                                            font-weight:500;
                                            &:focus{
                                                border-color:#E9E9E9;
                                                color:#707070;
                                                font-weight:500;
                                            }    
                                        }
                                    }
                                }                                    
                            }
                        }     
                    } 
                    .main-section-new{
                        float:left;
                        width:100%;
                        margin-top:20px;
                        .productDetail{
                            float:left;
                            width:100%;
                            h4{
                                font-weight: 400;
                                font-size: 24px;
                                line-height: 28px;
                                color: #5e8e22;
                                text-transform: capitalize;
                                margin-bottom: 2px;
                                .btnApp{
                                    font-weight:600;
                                    font-size: 14px;
                                    font-family: 'PT Sans';
                                    float: right;
                                    color: #7FB541;
                                    font-size: 14px;
                                    letter-spacing: 0;
                                    line-height: 25px;
                                    text-align: center;
                                    display: inline-block;
                                    padding: 2px 15px;
                                    height: 34px;
                                    background: #fff;
                                    border:2px solid #7FB541;
                                    border-radius: 5px;
                                    background: #fff;
                                    &:hover{
                                        background:#7FB541;
                                        color:#fff;
                                    }
                                }
                            }
                            span{
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                color: #666666;
                                display: inline-block;
                                width: 100%;
                                strong{
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 18px;
                                    color: #000000;
                                }
                            }
                            p{
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color: #000000;
                                margin-bottom: 3px;
                            }
                        }
                        .additivesList{
                            float:left;
                            width:100%;
                            margin-top: 15px;
                            h4{
                                font-weight: 700;
                                font-size: 20px;
                                line-height: 32px;
                                color: #000000;
                                font-family: 'PT Sans';
                                margin-bottom: 10px;
                            }
                            .itemList-col{   
                                display: flex;
                                flex-direction: column;
                                gap: 4px;
                                margin-bottom: 20px;
                                span{
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #666666;
                                    display: inline-block;
                                    width: 100%;
                                    strong{
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 18px;
                                        color: #000000;
                                    }
                                }
                            }
                            .smallBOx{                                
                                margin-bottom:10px;
                                display: flex;
                                flex-flow: wrap;
                                width: 100%;
                                .custom-3Col{
                                    display: flex;
                                    flex-flow: wrap;
                                    width: 33.33%;
                                    padding-right: 15px;
                                    margin-bottom:25px;   
                                    .smallBOx-inner{
                                        display: flex;
                                        width:100%;                                        
                                        .prdctImg {
                                            width: 33%;
                                            height: 100px;
                                            margin-right:5%;
                                            border-radius: 8px;
                                            img {
                                                width: 100%;
                                                height: 100%;
                                                border-radius: 8px;
                                            }
                                        }
                                        .prdcttext {
                                            width:62%;
                                            h6{
                                                margin-bottom: 4px;
                                                font-weight: 400;
                                                font-size: 20px;
                                                line-height: 26px;
                                                color: #000000;
                                            }
                                            span{
                                                font-weight: 400;
                                                font-size: 14px;
                                                line-height:20px;
                                                color: #666666;
                                                display: inline-block;
                                                width: 100%;
                                            }
                                            strong {
                                                font-weight: 400;
                                                font-size: 14px;
                                                line-height: 18px;
                                                color: #000000;
                                            }
                                        }
                                    }
                                }
                                .replacement-custom-2Col{
                                    display: flex;
                                    flex-flow: wrap;
                                    width:100%;                                    
                                    margin-bottom:25px;   
                                    .smallBOx-inner{
                                        display: flex;
                                        width:50%;    
                                        padding-right:25px;
                                        margin-bottom: 35px;
                                        @media screen and (max-width:991px){
                                            width:100%;    
                                            padding-right:0px;
                                        }
                                        &:nth-child(2n){
                                            padding-right:0px;
                                        }                                    
                                        .prdctImg {
                                            width: 33%;
                                            height: 120px;
                                            margin-right:5%;
                                            border-radius: 8px;
                                            img {
                                                width: 100%;
                                                height: 100%;
                                                border-radius: 8px;
                                            }
                                        }
                                        .prdcttext {
                                            width:62%;
                                            h6{
                                                margin-bottom: 4px;
                                                font-weight: 400;
                                                font-size: 20px;
                                                line-height: 26px;
                                                color: #000000;
                                            }
                                            span{
                                                font-weight: 400;
                                                font-size: 14px;
                                                line-height:20px;
                                                color: #666666;
                                                display: inline-block;
                                                width: 100%;
                                            }
                                            strong {
                                                font-weight: 400;
                                                font-size: 14px;
                                                line-height: 18px;
                                                color: #000000;
                                            }
                                        }
                                    }
                                }                                
                            }
                            .addTables{
                                float:left;
                                width:100%;
                                .edit-block-div{
                                    float:left;
                                    width:100%;
                                    label{
                                        font-weight: 700;
                                        font-size: 16px;
                                        line-height: 22px;
                                        color: #4f4f4f;
                                        display: inline-block;
                                        width: 100%;
                                        margin-bottom: 7px;
                                    }
                                }   
                                .commentSec {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 20px;
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 22px;
                                    color: #4f4f4f;
                                }     
                                .EditICOn {
                                    margin-left: 30px;
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 22px;
                                    color: #528019;
                                    display: flex;
                                    align-items: center;
                                    .edit-comment {
                                        margin-left: 10px;
                                        padding-bottom: 0px;
                                        margin-top: -4px;
                                    }
                                }                        
                            }
                            .formInput-div{
                                float:left;
                                width:100%;
                                margin: 20px 0;
                                label{
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 22px;
                                    color: #4f4f4f;
                                    display: inline-block;
                                    width: 100%;
                                    margin-bottom: 7px;
                                }
                                .css-1s2u09g-control{
                                    height:auto!important;
                                    min-height:40px!important;
                                }
                                .css-g1d714-ValueContainer{
                                    padding-top:0px;
                                    padding-bottom:0px;
                                }
                                input[type="text"]{                                    
                                    height:40px;   
                                    padding: 5px 10px;     
                                }
                            }
                            .docSection-div{
                                float:left;
                                margin-top:20px;
                                width:100%;
                                label{
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 22px;
                                    color: #4f4f4f;
                                    display: inline-block;
                                    width: 100%;
                                    margin-bottom: 7px;
                                }
                                .uploadSec {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    text-align: center;
                                }
                                .dummy {
                                    height: 62px;
                                }
                                .dragAndDrop {
                                    background: #ffffff;
                                    border: 2px dashed #c5c5c5;
                                    border-radius: 8px;
                                    width: auto;
                                    display: inline-flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 10px;
                                    max-height: 400px;
                                    span {
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 20px;
                                        text-align: center;
                                        color: #646969;
                                        margin-top: 15px;
                                    }
                                }
                                .docUpload {
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin: 0 -5px 25px;
                                }
                                .docUploadInner {
                                    text-align: center;
                                    margin: 0 5px 10px;
                                    span{
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 16px;
                                        color: #000000;
                                        margin-top: 5px;
                                        display: inline-block;
                                        width: 100%;
                                    }
                                }
                                .docImg {
                                    background: #edf2f4;
                                    border-radius: 8px !important;
                                    width: 115px;
                                    height: 115px;
                                    display: flex;
                                    position: relative;
                                    align-items: center;
                                    justify-content: center;
                                }                                
                                .removeBox {
                                    position: absolute;
                                    right: 0px;
                                    top: 0;
                                    border: none;
                                    background: transparent;
                                    img {
                                        width: 12px; 
                                        
                                    }
                                }
                                .document-image {
                                    /* border-radius: 8px !important; */
                                    width: 90%;
                                    max-height: 115px;
                                }
                                .ulTags {
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin: 0 -2px;
                                    li{
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 16px;                                        
                                        color: hsl(0, 0%, 20%);
                                        background-color: hsl(0, 0%, 90%);
                                        border-radius: 2px;
                                        padding: 3px 12px;
                                        display: inline-flex;
                                        margin: 0 2px 4px;
                                    }
                                }
                            }
                        }
                    } 
                    .tabsDetail-container{
                        padding:20px 0px;
                        .tabsListing{
                            display: -webkit-box;
                            display: -moz-box;
                            display: -ms-flexbox;
                            display: -moz-flex;
                            display: -webkit-flex;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            width: 100%;    
                            li{    
                                width: 49%;
                                margin-right: 2%;
                                margin-bottom: 30px;
                                position:relative;
                                &:nth-child(2n){
                                    margin-right:0px;
                                }                                                    
                                label{
                                    margin-bottom: 10px;  
                                    color: #73788E;
                                    font-family: 'PT Sans';
                                    font-size: 12px;
                                    letter-spacing: 0;
                                    line-height: 20px;
                                    text-align: left;
                                    width: 100%;
                                    margin-bottom: 10px;
                                    strong {
                                        width: 30px;
                                        height: 30px;
                                        margin-left: 6px;
                                        line-height: 18px;
                                        cursor: pointer;
                                        position: absolute;
                                        right: 0;
                                        top: 0;
                                        img {
                                            width: 100%;
                                        }
                                        .autoHide {
                                            opacity: 1;
                                            animation: fadeIn 2s;
                                            animation-delay: 1s;
                                            animation-fill-mode: forwards;
                                            color: #6EB724;
                                            .error{
                                                color:red;   
                                            }
                                            &.error{
                                                color:red;   
                                            }
                                        }
                                        @keyframes fadeIn {
                                            from {
                                                opacity: 1;
                                            }
                                            to {
                                                opacity: 0;
                                            }
                                        }
                                    }           
                                }                                           
                                input[type="text"], 
                                input[type="email"],  
                                input[type="password"]{                                                                
                                    background-color: #fff;
                                    height: 42px;                                    
                                    color: #73788E;
                                    &:focus{                            
                                        color:#73788E; 
                                        border-color:#E9E9E9;
                                    }
                                    &:focus::placeholder{                            
                                        color:#73788E; 
                                        border-color:#E9E9E9;
                                    }
                                    &:focus:-ms-input-placeholder{                            
                                        color:#73788E; 
                                        border-color:#E9E9E9;
                                    }
                                    &:focus::-ms-input-placeholder{                            
                                        color:#73788E; 
                                        border-color:#E9E9E9;
                                    } 
                                } 
                                textarea{                       
                                    color: #73788E;     
                                    background: #fff;
                                }                           
                                &.disabledselect{
                                    .css-b62m3t-container{
                                        pointer-events: none;
                                        cursor: not-allowed;
                                        opacity: 0.7;
                                        border: 1px solid #efefef;
                                        border-radius: 5px;
                                    }
                                }                                                                   
                            }
                        }    
                    }  
                }
                .modal-footer {
                    padding:20px 40px 20px;
                }
            }
            &.modalHeightFix{
                .modal-content{
                    margin: 50px auto 50px; 
                    .modal-body{
                        padding: 0 25px 30px;
                    }
                    .modal-footer{
                        padding-top:0px;
                    }
                }
            }
        }
        &.gene-Details-modal{
            .modal-content{
                max-width: initial;
                width: 80%;
                margin: 50px auto 0;
                @media screen and (max-width: 1440px){
                    margin:40px auto 0;
                }
                @media screen and (max-width: 1366px){
                    margin:30px auto 0;
                }
                .modal-header{
                    h3{
                        color: #262726;
                        font-size: 20px;
                        font-weight: bold;
                        letter-spacing: 0;
                        display:inline-block;
                        margin-bottom: 0px;
                        line-height: 26px;
                    }    
                }
                .modal-body{
                    padding:0 25px 25px;
                    .replacementTagging-container{
                        float:left;
                        width:100%;
                        margin-top:20px;
                        .replacementTagging-list{
                            float:left;
                            width:100%;
                            li{
                                float:left;
                                width:100%; 
                                margin-bottom:20px; 
                                margin-right:0;
                                position:relative;    
                                .mergeCol{
                                    width:30.66%;
                                    margin-right:4%;
                                    float: left;
                                    &:last-child{
                                        margin-right:0%;
                                    }         
                                }                                    
                                label{
                                    float: left;
                                    width: 100%;
                                    font-weight: 500;
                                    color: #73788E;
                                    font-family: "PT Sans";
                                    font-size: 14px;
                                    letter-spacing: 0;
                                    line-height: 20px;
                                    text-align: left;
                                    width: 100%;
                                    margin-bottom: 4px; 
                                    strong{
                                        color:#6EB724;
                                        font-weight: 500;
                                        cursor:pointer;  
                                    } 
                                    .copyFasta{
                                        display: inline-block;
                                        margin-left:5px;
                                        cursor:pointer;  
                                        position:relative;    
                                        a{
                                            img {
                                                width:20px;   
                                                &.imgFirst {
                                                    display: inline-block;
                                                    opacity: 1;
                                                }
                                                &.imghover {
                                                    display: none;
                                                    opacity: 0;
                                                }
                                            }
                                            &:hover{
                                                img {
                                                    &.imgFirst {
                                                        display: none;
                                                        opacity: 0;
                                                    }
                                                    &.imghover {
                                                        display: inline-block;
                                                        opacity: 1;
                                                    }
                                                }    
                                            }
                                        }   
                                        .tooltip-hover{
                                            position: absolute;
                                            background: #000;
                                            font-weight: 500;
                                            color: #fff;
                                            font-family: "PT Sans";
                                            font-size: 12px;
                                            letter-spacing: 0;
                                            line-height: 20px;
                                            top: -20px;
                                            padding: 2px 10px;
                                            border-radius: 3px;
                                            transform: translate(-50%, -50%);
                                            left: 50%;
                                            &:after{
                                                top: 100%;
                                                left: 50%;
                                                border: solid transparent;
                                                content: "";
                                                height: 0;
                                                width: 0;
                                                position: absolute;
                                                pointer-events: none;
                                                border-color: rgba(0, 0, 0, 0);
                                                border-top-color: #000;
                                                border-width: 7px;
                                                margin-left: -7px;
                                            }
                                        } 
                                    }
                                }
                                span{
                                    float: left;
                                    width: 100%;
                                    word-wrap: break-word;
                                    font-weight: 500;
                                    color: #262726;
                                    font-family: "PT Sans";
                                    font-size: 16px;
                                    letter-spacing: 0;
                                    line-height: 20px;
                                    text-align: left;
                                    width: 100%;   

                                }
                                .css-1s2u09g-control{
                                    height:auto!important;
                                }
                                .css-1s2u09g-control, .css-1pahdxg-control{
                                    height:auto!important;
                                }
                                .css-ackcql{
                                    input{
                                        height:auto;
                                    }
                                }
                                .css-6j8wv5-Input{ 
                                    input{
                                        height:auto;
                                    }
                                } 
                                .error{
                                        margin: 0;
                                    color: red;
                                    font-weight: 500;
                                    position: absolute;
                                    bottom: -18px;
                                    font-size: 12px;
                                }  
                            }
                        }
                    }                       
                }                
            }
            &.modalHeightFix{
                .modal-content{
                    margin: 50px auto 50px; 
                    .modal-body{
                        padding: 0 25px 30px;
                    }
                    .modal-footer{
                        padding-top:0px;
                    }
                }
            }
        }
    }
    &.plantPreview-Modal{  
        .plantsParts-container .add-functionalities .formListing-block .formSection-New .formListing li strong textarea{
            background:#fff;
        }  
        .middle-wrapper{
            padding:0px;
            margin:0px;
        }   
        .full-left-panel-tabs{
            gap:0px;
            padding:0px;
            .fixed-right-panel{
                .tab-content{
                    box-shadow:none;
                    padding:0px;
                    .tab-pane{
                        &.plantsTabs-inner{
                            .plantsTabs{
                                .nav{
                                    /*justify-content: center;*/
                                    .nav-link{
                                        font-size: 16px;
                                        line-height:18px;
                                        padding: 10px 22px 10px;   
                                        &.active{
                                            font-size:16px;
                                        }
                                    }
                                }
                                .tab-content{
                                    .dashboard-form-container{
                                        padding:0px;
                                    }
                                }
                            }    
                        }
                        .css-16xfy0z-control{
                            background:#fff;
                        }
                        .css-1h01tm3-Input{
                            margin:0px;
                            padding-top:0px;
                            padding-bottom:0px;
                        }
                        .noPointer{
                            pointer-events: none;
                        }
                    }
                }                                
            }    
        }         
    }
}

.modal-95w {
    width: 95%;
    max-width: none !important;
}

.tableBtnNew{
    width: 25px;
    height: 25px;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    transition: all 0.3s ease-in-out 0s;
    .small-icon{
        width:15px;
        height:15px;     
        &.bigIcon{
            width:22px;
            height:22px;
        }
    }
    &:hover {
        background: #5e8e22;
        transition: all 0.3s ease-in-out 0s;
        img{
            filter: brightness(0) invert(1);
        }
    }
}