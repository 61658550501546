.fixedBg {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: -1;
    width: 100%;
    height: 100vh;
}

.loginBg-shapes {
    position: fixed;
    bottom: 0px;
    right: 20px;

    img {
        @media screen and (max-width:1600px) {
            width: 380px;
        }

        @media screen and (max-width:1366px) {
            width: 300px;
        }
    }
}

.loginBg-shapes {
    position: fixed;
    bottom: 0px;
    right: 20px;

    img {
        @media screen and (max-width:1600px) {
            width: 380px;
        }

        @media screen and (max-width:1366px) {
            width: 300px;
        }
    }
}

.loginConatiner {
    width: 100%;
    padding: 0px 0px;
    max-width: 420px;
    margin: 164px auto 25px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-flow: wrap;

    @media screen and (max-width:1600px) {
        margin: 110px auto 25px;
    }

    @media screen and (max-width:1366px) {
        margin: 70px auto 20px;
        max-width: 390px;
    }

    .loginSection {
        float: left;
        width: 100%;

        .loginHead {
            color: #262726;
            margin-bottom: 38px;
            font-size: 31px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 30px;
            text-align: center;

            @media screen and (max-width:1366px) {
                font-size: 28px;
                margin-bottom: 35px;
            }
        }

        .forgotInfo {
            color: #666;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 23px;
            text-align: center;
            font-weight: 700;
        }

        .login-form {
            float: left;
            width: 100%;

            li {
                display: block;
                float: left;
                width: 100%;
                position: relative;
                margin: 0 0 30px;

                @media screen and (max-width:1440px) {
                    margin: 0 0 25px;
                }

                &:last-child {
                    margin-bottom: 0px;
                }

                .tooltip-custom {
                    top: 0px;
                }

                .forgotPass {
                    position: absolute;
                    right: 0px;
                    top: -15px;
                    color: #659d24;
                    font-size: 14px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 20px;

                    &:hover {
                        color: #659d24;
                    }
                }

                strong {
                    position: absolute;
                    right: 12px;
                    top: 18px;

                    @media screen and (max-width:1366px) {
                        top: 14px;
                    }

                    .eye-icon {
                        cursor: pointer;

                        &:hover {
                            filter: grayscale(1) invert(1);
                        }
                    }
                }

                .checkbox {
                    float: left;
                    width: 100%;
                    margin-bottom: 0px;

                    label {
                        display: inline-block;
                        position: relative;
                        font-size: 16px;
                        font-weight: 400;
                        color: #262726;
                        line-height: 19px;
                        margin: 0;

                        &:before {
                            content: "";
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            border: 2px solid #E9E9E9;
                            background-color: #fff;
                            float: left;
                            margin-right: 7px;
                            cursor: pointer;
                            border-radius: 4px;
                        }

                        &:after {
                            display: inline-block;
                            width: 20px;
                            line-height: 23px;
                            cursor: pointer;
                            height: 20px;
                            float: left;
                            border: 2px solid #E9E9E9;
                            margin-right: 7px;
                            border-radius: 4px;
                        }
                    }

                    input[type="checkbox"] {
                        opacity: 0;
                        display: none;

                        &:checked+label::after {
                            /* background: #96BE4F url(assets/images/tick-white.svg) no-repeat; */
                            content: "";
                            border-color: #96BE4F;
                            background-position: center;
                            background-size: 16px;
                        }

                        &:checked+label::before {
                            display: none;
                        }
                    }
                }

                input[type="text"],
                input[type="email"],
                input[type="password"] {
                    /* display: block;
                     width: 100%;
                     background: none;
                     font-weight: 700;*/
                    display: block;
                    width: 100%;
                    background: #f4f4f4;
                    /* Background color */
                    border: 1px solid #ccc;
                    /* Border color */
                    border-radius: 5px;
                    /* Rounded corners */
                    padding: 10px;
                    /* Padding */
                    font-weight: 700;
                    transition: border-color 0.3s ease;
                    /* Smooth transition for border color */

                    &:focus {
                        border-color: #7FB541;
                        /* Border color on focus */
                        outline: none;
                        /* Remove default outline */
                    }
                }
            }
        }

        .btnSet {
            float: left;
            width: 100%;
            margin-top: 32px;

            .btn-big {
                line-height: 35px;
                width: 100%;
                background: #7FB541;
                height: 48px;
                color: #fff;
                font-size: 16px;
                font-weight: bold;

                @media screen and (max-width:1366px) {
                    line-height: 30px;
                    height: 44px;
                }

                &:hover {
                    background: #659d24;
                }

                label {
                    width: 32px;
                    height: 32px;
                    margin-left: 6px;
                    line-height: 18px;
                    cursor: pointer;
                }
            }
        }
    }

    &.verifyOtp {
        margin: 100px auto 25px;

        @media screen and (max-width:1440px) {
            margin: 70px auto 20px;
        }

        @media screen and (max-width:1366px) {
            margin: 50px auto 20px;
        }

        .loginSection {
            .loginHead {
                @media screen and (max-width:1440px) {
                    margin-bottom: 27px;
                    font-size: 28px;
                }

                @media screen and (max-width:1366px) {
                    margin-bottom: 15px;
                    font-size: 24px;
                }
            }

            .forgotInfo {
                @media screen and (max-width:1440px) {
                    font-size: 15px;
                    line-height: 21px;
                }

                @media screen and (max-width:1366px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        .loginFooter {
            margin-top: 100px;

            @media screen and (max-width:1440px) {
                margin-top: 60px;
            }

            @media screen and (max-width:1366px) {
                margin-top: 40px;
            }
        }

        .error-Danger {
            border: 2px solid #D41C27 !important;
            color: #D41C26 !important;

            &:focus {
                border-color: #D41C26 !important;
                color: #D41C26 !important;
            }

            &:focus::placeholder {
                border-color: #D41C26 !important;
                color: #D41C26 !important;
            }

            &::placeholder {
                border-color: #D41C26 !important;
                color: #D41C26 !important;
            }

            &:focus:-ms-input-placeholder {
                border-color: #D41C26 !important;
                color: #D41C26 !important;
            }

            &:focus::-ms-input-placeholder {
                border-color: #D41C26 !important;
                color: #D41C26 !important;
            }
        }
    }
}