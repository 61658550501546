.gene-data-grid {
  .table-wrapper {
    max-height: 300px;
    overflow-y: auto;
    .table-formating {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
      border-spacing: 0;
      thead { 
        position: sticky;
        top: 0;
        z-index: 5;     
        th {
          background-color: #fff;
          border: 1px solid #ccc;
          padding: 10px;
          text-align: left;
          font-weight: bold;
          font-size: 12px;
          color: #6C7789;
          border-bottom:1px solid #E9E9E9;
          border-top: 1px solid #E9E9E9;
          border-left: 1px solid #f7f7f7;
          border-right: 1px solid #f7f7f7;

          &:first-child {
            text-align: left;
            width: 80px;
          }
          &:last-child {
            text-align: left;
            width: 100px;
          }
        }
      }
      tbody {
        z-index: 4;
        td {
          border: 1px solid #ccc;
          padding: 10px;
          text-align: left;
          font-size: 12px;
          color: #6C7789;
          font-weight: 400;
          border-bottom: 1px solid #f7f7f7;
          border-left: 1px solid #f7f7f7;
          border-right: 1px solid #f7f7f7;
          height: 63px;
          word-break: break-word;
          &:first-child {
            text-align: left;
            width: 30%;
          }
          &:last-child {
            text-align: left;
            width: 70%;
          }

          div {
           
            white-space: normal; 
            overflow: visible; 
            text-overflow: clip;
            padding-right: 15px;
            @media screen and (max-width: 1366px) {
              padding-right: 10px;
            }
          }
          .action-Icon {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
