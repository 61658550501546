/* All Fonts Family Starts Here */
@font-face {
    font-family: 'PT Sans';
    src: url('../assets/fonts/PTSans-Regular.woff2') format('woff2'),
    url('../assets/fonts/PTSans-Regular.woff') format('woff'),
    url('../assets/fonts/PTSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../assets/fonts/PTSans-Italic.woff2') format('woff2'),
    url('../assets/fonts/PTSans-Italic.woff') format('woff'),
    url('../assets/fonts/PTSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../assets/fonts/PTSans-BoldItalic.woff2') format('woff2'),
    url('../assets/fonts/PTSans-BoldItalic.woff') format('woff'),
    url('../assets/fonts/PTSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../assets/fonts/PTSans-Bold.woff2') format('woff2'),
    url('../assets/fonts/PTSans-Bold.woff') format('woff'),
    url('../assets/fonts/PTSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* All Fonts Family End Here */

html {
    font-size: 16px;
}

body {
    font-family: 'PT Sans';
    font-weight: 400;
    font-size: 1.6rem;
    padding: 0px;
    margin: 0px;
    color: #262726;
    line-height: 18px;
}
b, strong {
    font-weight:500!important;
}
a {
    color: #6A7587;
    text-decoration: none;
}

ol,
ul {
    padding-left: 0rem;
    margin: 0px;
}

li {
    list-style: none;
}
.bg-overlay{  
    position:fixed;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
    z-index:999;                   
    opacity:0;
    visibility:hidden;
    background:rgba(255,255,255,0.5);
    transition: all ease 1s;
    -webkit-transition: all ease 1s; 
    -moz-transition: all ease 1s;
    -ms-transition: all ease 1s;
    -o-transition: all ease 1s;
    &.show{
       opacity:1; 
       visibility:visible;
    }
    span{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        img{
            width:120px;
        }
    }
}
.btn-custom{
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  display: inline-block;
  padding: 5px 20px;
  height: 38px;
  background: #7FB541;
  border: none;
  border-radius: 5px;
  @media screen and (max-width:1366px){
      font-size:0.9rem;
  }
} 

.btn-advanced-options{
    color: #0d0d0d;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    background: #E9E9E9;
    border: none;
    border-radius: 5px;
    padding: 5px 20px;
  } 
  
.btn-close:focus{
  box-shadow:none;
}
.MB30{
    margin-bottom:30px;     
}
.small-title {
    float: left;
    width: 100%;
    h2 {
        color: #262726;
        margin: 0px;
        font-size: 1.563rem;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 32px;
    }
}

input,
button,
select,
optgroup,
textarea {
    font-family: 'PT Sans';
}
.btn-check:focus + .btn, .btn:focus{
    box-shadow:none;
}
.error-Danger {
    border: 2px solid #D41C27 !important;
    color: #D41C26 !important;
    &:focus {
        border-color: #D41C26 !important;
        color: #D41C26 !important;
    }
    &:focus::placeholder {
        border-color: #D41C26 !important;
        color: #D41C26 !important;
    }
    &::placeholder {
        border-color: #D41C26 !important;
        color: #D41C26 !important;
    }
    &:focus:-ms-input-placeholder {
        border-color: #D41C26 !important;
        color: #D41C26 !important;
    }
    &:focus::-ms-input-placeholder {
        border-color: #D41C26 !important;
        color: #D41C26 !important;
    }
}

.disabledInput{                                
    pointer-events: none;                                 
}
.error-msz{    
    color: #D41C27 !important;
    font-size:12px;
    width:100%;
    display:block;
    height:16px;
    bottom:-17px;
    position:absolute;
    left:0px;    
    margin:0px;
}
.number-error-msz{    
    color: #D41C27 !important;
    font-size:12px;
    width:100%;
    display:block;
    position:absolute;
    left:0px;    
    margin:0px;
}
.spiner{
    width: 30px;
    height: 30px;
    margin-left: 6px;
    line-height: 18px;
    display:inline-block;
    cursor: pointer;
 }
.messagePopup{
    float:left;
    width:343px;
    min-height: 48px;  
    border-radius: 3px;  
    position: fixed;
    right: -550px;
    top: 120px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    transition: all ease 1s;
    -webkit-transition: all ease 1s; 
    -moz-transition: all ease 1s;
    -ms-transition: all ease 1s;
    -o-transition: all ease 1s;
}
.showMessage{
    right:15px; 
    transition: all ease 0.5s;
    -webkit-transition: all ease 1s; 
    -moz-transition: all ease 1s;
    -ms-transition: all ease 1s;
    -o-transition: all ease 1s;
}
.successMessage{
    background-color:#6EB724;
}
.helpMessage{
    background-color:#FF9138; 
}
.warningMessage{
    background-color:#F46363;  
}
.popContent{
    float:left;
    width:100%;  
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    position: relative;
    padding:10px 45px 10px 10px;
    span{
        color: #FFFFFF;  
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.2px;
        padding-left:10px;  
    }  
    .closePop{
        position:absolute;
        right:13px;
        top:12px;  
    }
}    
.tooltip-custom {
    position: absolute;
    background-color: #D41C26;
    min-height: 34px;
    border-radius: 5px;
    top: 25px;
    right: -212px;
    min-width: 141px;
    z-index: 99999;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    width: 200px;
    .closeIcon {
        position: absolute;
        left: 7px;
        top: 5px;
    }
    .tooltiptext {
        color: #fff;
        text-align: left;
        padding: 5px 15px 5px 15px;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height:16px;
        width: 100%;
        float: left;
        &:after {
            content: "";
            position: absolute;
            top:17px;
            right: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent #D41C26 transparent transparent;
        }
        &:hover{
            .tooltiptext {
                visibility: visible;
            }
        }
    }
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    color: #7FB541;
    font-size: 0.875rem;
    font-family: 'PT Sans';
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 2px solid #E9E9E9;
    display: block;
    width: 100%;
    height: 46px;
    padding: 5px 15px;
    background:transparent;
    box-sizing: border-box;
    outline: none; 
    &::-ms-reveal {
        display: none;
    }
    &:focus {
        border-color: #7FB541;
        color: #7FB541;
    }

    &:placeholder {
        border-color: #7FB541;
        color: #7FB541;
    }

    &:focus::placeholder {
        border-color: #7FB541;
        color: #7FB541;
    }

    &:focus:-ms-input-placeholder {
        border-color: #7FB541;
        color: #7FB541;
    }

    &:focus::-ms-input-placeholder {
        border-color: #7FB541;
        color: #7FB541;
    }
    @media screen and (max-width:1366px) {
        height: 48px;
    }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    color: #7FB541;
    font-size: 0.875rem;
    font-family: 'PT Sans';
    font-weight: 700;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 2px solid #E9E9E9;
    display: block;
    width: 100%;
    height: 150px;
    padding: 10px 15px;
    background: #fff;
    box-sizing: border-box;
    outline: none;
}

select {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    color: #7FB541;
    font-size: 0.875rem;
    font-family: 'PT Sans';
    font-weight: 700;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 2px solid #E9E9E9;
    display: block;
    width: 100%;
    height: 56px;
    padding: 5px 35px 5px 15px;
    background: #fff url(../assets/images/down-arrow.png) no-repeat 94% 19px;
    background-size: 10px;
    box-sizing: border-box;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
        border-color: #7FB541;
        color: #7FB541;
    }

    &:focus::placeholder {
        border-color: #7FB541;
        color: #7FB541;
    }

    &:focus:-ms-input-placeholder {
        border-color: #7FB541;
        color: #7FB541;
    }

    &:focus::-ms-input-placeholder {
        border-color: #7FB541;
        color: #7FB541;
    }

    @media screen and (max-width:1366px) {
        height: 48px;
    }
}


.radio-panel {
    display: inline-block;
    p {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 0px;
        &:last-child {
            margin-right: 0px;
        }
    }
    label {
        display: inline-block;
        position: relative;
        color: #6C7789;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
        padding-left: 30px;
        cursor:pointer;
        &:before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 1px solid rgba(108, 119, 137, 0.5);
            background-color: #fff;
            border-radius: 100%;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            float: left;
            margin-right: 7px;
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 2px;
        }
        &:after {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 2px;
            width: 20px;
            line-height: 23px;
            cursor: pointer;
            height: 20px;
            float: left;
            border: 1px solid rgba(108, 119, 137, 0.5);
            margin-right: 7px;
            border-radius: 100%;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
        }
    }
    input[type="radio"] {
        opacity: 0;
        display: none;
    }
    input[type="radio"]:checked+label::after {
        background: #96BE4F url(../assets/images/tick-white.svg) no-repeat;
        content: "";
        border-color: #96BE4F;
        background-position: center;
        background-size: 14px;
    }
    input[type="radio"]:checked+label::before {
        display: none;
    }
}

.css-13cymwt-control{
    border:2px solid #e9e9e9!important;
}
.css-b62m3t-container{
    width:100%;
}
.css-19bb58m input{
    padding: 0px 8px!important; 
    height: 38px!important;
    min-height: 38px!important;
    color: #6C7789!important;
}
.css-qbdosj-Input input{
    padding: 0px 8px!important; 
    height: 38px!important;
    min-height: 38px!important;
    color: #6C7789!important;
}
.css-19bb58m{
    margin: 0px!important;
    padding-bottom: 0px!important;
    padding-top: 0px!important;
}
.css-qbdosj-Input{
    margin: 0px!important;
    padding-bottom: 0px!important;
    padding-top: 0px!important;
}
.css-t3ipsp-control{
    box-shadow: none!important;
    border: 2px solid #E9E9E9!important;
}
.css-1s2u09g-control, .css-1pahdxg-control{
    width: 100%!important;    
    min-height: 38px!important;
    height: 38px!important;
    box-shadow: none!important;
    -webkit-box-shadow: none!important;
    -moz-box-shadow: none!important;
    font-size: 0.75rem!important;
    font-weight: bold!important;
    letter-spacing: 0!important;
    color: #73788E!important;
    line-height: 20px!important;
    border-radius: 4px!important;
    -webkit-border-radius: 4px!important;
    -moz-border-radius: 4px!important;
    border: none!important;    
    padding:0px!important;
    box-sizing: border-box!important;
    background-color: #F5F5FA!important;
    outline: none!important;
}
.css-319lph-ValueContainer{
    height: 38px!important; 
    padding:0px 10px!important;
}
.css-1okebmr-indicatorSeparator{
    margin-left: 0px!important;
    width: 1px!important;
}
.css-1f43avz-a11yText-A11yText{
    margin-left: 0px!important;
    width: 1px!important;   
}
.css-14el2xx-placeholder{
    margin:0px!important;
}
.css-6j8wv5-Input {
    margin: 0!important;
    padding-bottom: 0!important;
    padding-top: 0!important;
    color: #73788E!important;
}
.css-1u9des2-indicatorSeparator{
    display:none!important; 
}
.css-1okebmr-indicatorSeparator{
    display:none!important; 
}
.css-6j8wv5-Input input{
   color: #73788E!important; 
}
.css-1dimb5e-singleValue{
   color: #73788E!important;
       font-weight: 400;
   .css-scientificName-option {
        display: none;
   }    
}
.css-qc6sy-singleValue{
   color: #73788E!important;
   .css-scientificName-option {
        display: none;
   }    
}
.css-1nmdiq5-menu{
    margin-top:0px!important;
    border: 1px solid #E9E9E9!important;  
    border-radius: 0 0 4px 4px!important;
    box-shadow:none!important;  
    border-top:none!important;  
}
.css-1n7v3ny-option{ 
    color: #6EB724!important;
    font-size: 14px!important;
    letter-spacing: 0!important;
    line-height:18px!important;
    display: block!important;
    background-color: #fff!important;
    cursor:pointer!important;
    font-weight: 400!important;
}
.css-yt9ioa-option{
    color: #6C7789!important;
    cursor:pointer!important;
    font-size: 14px!important;
    letter-spacing: 0!important;
    line-height: 18px!important;
    display: block!important;
    background-color: #fff!important;   
    font-weight: 400!important;
}
.css-4ljt47-MenuList{
    max-height: 300px!important;
    overflow-y: auto!important;
    padding-bottom: 0!important;
    padding-top: 0!important;
}
.css-9gakcf-option{
    background:#7FB541!important;
    color: #fff!important;
    font-weight: 400!important;
}
.css-1nmdiq5-menut{
    max-height: 300px!important;
    overflow-y: auto!important;
    padding-bottom: 0!important;
    padding-top: 0!important;
}
.css-d7l1ni-option{
    background:#7FB541!important;
    color: #fff!important;
    font-weight: 400!important;   
}
.css-searchText{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    align-items: center; 
    justify-content: space-between;
}
.css-scientificName-option{
    font-size: 12px;
    color: #fff;
    background: #7FB541;
    padding: 3px 10px;
    border-radius: 6px;  
    font-weight: 800;
}
.react-datepicker-popper{
    width:100%;
    @media screen and (max-width:1440px){
        min-width:250px;
    }
}
.react-datepicker{
    width:100%;   
}
.react-datepicker__month-container{
    width:100%;
}
.react-datepicker__aria-live{
    display:none; 
}
.css-tr4s17-option{
    background:#7FB541!important;
}
.full-left-panel-tabs{
    float: left;
    width: 100%;
    display: flex;
    flex-flow: row;
    gap:25px;
    @media screen and (max-width:1440px){
        gap:22px;
    }
    @media screen and (max-width:1366px){
        gap:20px;
    }
    .fixed-left-panel{
        flex: 0 0 170px;
        @media screen and (max-width:1440px) {
            flex: 0 0 150px;
        }
        .nav-tabs {
            border:none;
            display: block;
            width: 100%;
            .nav-link{
                width: 100%;
                margin:0px;
                font-size: 16px;
                font-weight: 600;
                color: #6C7789;
                letter-spacing: 0.2px;
                padding:15px 28px 15px 15px;
                text-align: left;
                border: none;
                border-radius:8px;
                position: relative;
                margin-bottom:2px;     
                @media screen and (max-width:1440px){
                    font-size:14px;   
                    padding:13px 28px 13px 15px;
                }
                &.active{
                    background:#7FB541;
                    color:#fff;
                    border-radius:8px;
                }
                &:hover{
                    background:#7FB541;
                    color:#fff;
                    border-radius:8px;
                }
                &:after{
                    opacity:0;
                    visibility:hidden;   
                    position: absolute;
                    right:30px;
                    width:20px;
                    height:20px;
                    top: 50%;
                    content: "";
                    background:url(../assets/images/arrow-right.png) no-repeat;  
                    background-size: 100%;
                    transform: translate(-50%, -50%);           
                    -webkit-transform: translate(-50%, -50%);           
                    -moz-transform: translate(-50%, -50%); 
                    transition: all ease 0.5s;
                    -webkit-transition: all ease 0.5s; 
                    -moz-transition: all ease 0.5s;
                    -ms-transition: all ease 0.5s;
                    -o-transition: all ease 0.5s;      
                }
                &.active, &:hover{
                    &:after{
                        opacity:1;                        
                        visibility: visible; 
                        right:5px;        
                        transition: all ease 0.5s;
                        -webkit-transition: all ease 0.5s; 
                        -moz-transition: all ease 0.5s;
                        -ms-transition: all ease 0.5s;
                        -o-transition: all ease 0.5s;
                        @media screen and (max-width:1366px) {
                            right:-2px;        
                        }
                    }
                }
            }
        }
    }
    .fixed-right-panel{
        width:100%;   
        .tab-content{
            float:left; 
            width:100%;   
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            padding:25px;
            border-radius: 5px; 
            position:relative;    
            .radio-btn-container{
                position:absolute;
                right:20px;
                top:13px;       
                .radioBtn-Type{
                    margin-top: 0;
                    float: right;              
                    .radio{  
                        display: inline-block;              
                        p{
                            display:inline-block; 
                            margin-right:20px;  
                            margin-bottom:0px;        
                            @media screen and (max-width:1440px){
                                margin-right:15px;  
                            }             
                            &:last-child{
                                margin-right:0px; 
                            }
                        }    
                        label{
                            display: inline-block; 
                            position: relative;                               
                            font-size: 16px;                      
                            font-weight:600;
                            color: #69778B; 
                            line-height: 25px; 
                            margin: 0;    
                            cursor:pointer;  
                            @media screen and (max-width:1440px){
                                font-size:14px;   
                                line-height: 20px;   
                            }          
                            &:before{
                                content: "";
                                display: inline-block;                   
                                width:24px;
                                height:24px;                                  
                                border:2px solid #73788e;
                                background-color: #fff;    
                                border-radius:100%;
                                -webkit-border-radius:100%;              
                                -moz-border-radius:100%;              
                                float: left;
                                margin-right:7px;
                                cursor: pointer;
                                @media screen and (max-width:1440px){
                                    width:20px;    
                                    margin-right: 5px;
                                    height:20px;  
                                } 
                            }
                            &:after{               
                                display: inline-block;                  
                                width:24px;
                                line-height: 23px; 
                                cursor: pointer;
                                height:24px; 
                                float: left;                  
                                border:2px solid #73788e;
                                margin-right:7px;
                                border-radius:100%;
                                -webkit-border-radius:100%;              
                                -moz-border-radius:100%;  
                                @media screen and (max-width:1440px){
                                    width:20px;    
                                    margin-right: 5px;
                                    height:20px;  
                                }                       
                            }  
                        }      
                        input[type="radio"]{
                            opacity: 0;
                            display: none;                
                        }
                        input[type="radio"]:checked + label::after{
                            background:#6EB724 url(../assets/images/tick-white.svg) no-repeat;
                            content:"";                   
                            border-color:#6EB724;  
                            background-position: center;
                            background-size: 16px;
                        }
                        input[type="radio"]:checked + label::before {
                          display:none;    
                        }
                    }    
                }       
            }
            .tab-pane{
                padding:0px; 
                &.plantsTabs-inner{
                    float: left;
                    width: 100%;
                    margin: 0;
                    .nav{
                        justify-content: left;
                        .nav-link{
                            font-size:20px;   
                            @media screen and (max-width:1680px) {
                                font-size:18px;   
                            }                
                            @media screen and (max-width:1440px) {
                                font-size:17px;   
                            }
                            @media screen and (max-width:1366px) {
                                font-size:16px;   
                            }
                            @media screen and (max-width:991px) {
                                font-size:18px; 
                                white-space: nowrap;  
                                min-width:auto;   
                            }                
                            &.active{
                                background:#F5F7FB;
                                font-size:20px; 
                                @media screen and (max-width:1680px) {
                                    font-size:18px;   
                                }                    
                                @media screen and (max-width:1440px) {
                                    font-size:17px;   
                                }
                                @media screen and (max-width:1366px) {
                                    font-size:16px;   
                                } 
                                @media screen and (max-width:991px) {
                                    font-size:18px;   
                                }   
                            }
                        }
                    }        
                    .tab-content{
                        background: #F5F7FB;
                        border-radius: 0 16px 16px 16px;
                        padding: 40px 54px 60px;
                        box-shadow: none;
                        @media screen and (max-width:991px) {
                            padding: 40px 25px 60px;
                            border-radius: 0px 0px 16px 16px;
                        }
                        .tabsDetail-container{
                            .tabsListing{
                                display: -webkit-box;
                                display: -moz-box;
                                display: -ms-flexbox;
                                display: -moz-flex;
                                display: -webkit-flex;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                width: 100%;    
                                li{                                                          
                                    label{
                                        margin-bottom: 10px;             
                                    }                                           
                                    input[type="text"], 
                                    input[type="email"],  
                                    input[type="password"]{                            
                                        background-color: #fff;
                                        &:focus{                            
                                            color:#73788E; 
                                        }
                                        &:focus::placeholder{                            
                                            color:#73788E; 
                                        }
                                        &:focus:-ms-input-placeholder{                            
                                            color:#73788E; 
                                        }
                                        &:focus::-ms-input-placeholder{                            
                                            color:#73788E; 
                                        } 
                                    } 
                                    textarea{                            
                                        background: #fff;
                                    }                           
                                    &.disabledselect{
                                        .css-b62m3t-container{
                                            pointer-events: none;
                                            cursor: not-allowed;
                                            opacity: 0.7;
                                            border: 1px solid #efefef;
                                            border-radius: 5px;
                                        }
                                    }                                                                   
                                }
                            }    
                        }
                    }        
                }
                #additive-replacement-data-grid{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    .filterContainer{
                        width:100%;
                        .filterSection{
                            width:auto;
                            .subHeading{
                                width:auto;
                            }
                        }   
                    }
                }
                #additive-assignment-data-grid{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    .filterContainer{
                        width:100%;
                        .filterSection{
                            width:auto;
                            .subHeading{
                                width:auto;
                            }
                        }   
                    }
                }
                .sustainability-yurveda-form{
                    padding:0px;
                    float:left;
                    width:100%;
                    .tabsDetail-container {
                        float: left;
                        width: 100%;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -moz-flex;
                        display: -webkit-flex;
                        display: flex;
                        .tabsListing {
                            display: -webkit-box;
                            display: -moz-box;
                            display: -ms-flexbox;
                            display: -moz-flex;
                            display: -webkit-flex;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            width: 100%;
                            li {
                                position: relative;
                                margin-bottom: 28px;
                                flex: 0 48%;
                                @media screen and (max-width: 991px) {
                                    flex: 0 100%;
                                }
                                &:last-child {
                                    margin-bottom: 0px;
                                }
                                label {
                                    color: #73788E;
                                    font-family: 'PT Sans';
                                    font-size: 12px;
                                    letter-spacing: 0;
                                    line-height: 20px;
                                    text-align: left;
                                    width: 100%;
                                    margin-bottom: 10px;
                                    strong {
                                        width: 30px;
                                        height: 30px;
                                        margin-left: 6px;
                                        line-height: 18px;
                                        cursor: pointer;
                                        position: absolute;
                                        right: 0;
                                        top: 0;
                                        img {
                                            width: 100%;
                                        }
                                        .autoHide {
                                            opacity: 1;
                                            animation: fadeIn 2s;
                                            animation-delay: 1s;
                                            animation-fill-mode: forwards;
                                            color: #6EB724;
                                        }
                                        @keyframes fadeIn {
                                            from {
                                                opacity: 1;
                                            }
                                            to {
                                                opacity: 0;
                                            }
                                        }
                                    }
                                }
                                input[type="text"],
                                input[type="email"],
                                input[type="password"] {
                                    width: 100%;
                                    height: 38px;
                                    box-shadow: none;
                                    -webkit-box-shadow: none;
                                    -moz-box-shadow: none;
                                    font-size: 12px;
                                    font-weight: bold;
                                    letter-spacing: 0;
                                    color: #73788E;
                                    line-height: 20px;
                                    border-radius: 4px;
                                    -webkit-border-radius: 4px;
                                    -moz-border-radius: 4px;
                                    border: none;
                                    display: block;
                                    padding: 5px 15px;
                                    box-sizing: border-box;
                                    background-color: #F5F5FA;
                                    outline: none;
                                    &:focus {
                                        color: #73788E;
                                    }
                                    &:focus::placeholder {
                                        color: #73788E;
                                    }
                                    &:focus:-ms-input-placeholder {
                                        color: #73788E;
                                    }
                                    &:focus::-ms-input-placeholder {
                                        color: #73788E;
                                    }
                                    &.disabledField {
                                        background: #efefef!important;
                                        border: 1px solid #ccc!important;
                                    }
                                }
                                textarea {
                                    box-shadow: none;
                                    -webkit-box-shadow: none;
                                    -moz-box-shadow: none;
                                    color: #73788E;
                                    font-size: 0.75rem;
                                    font-family: 'PT Sans';
                                    font-weight: 700;
                                    border-radius: 4px;
                                    -webkit-border-radius: 4px;
                                    -moz-border-radius: 4px;
                                    border: none;
                                    display: block;
                                    width: 100%;
                                    height: 93px;
                                    padding: 10px 15px;
                                    background: #F5F5FA;
                                    box-sizing: border-box;
                                    outline: none;
                                    resize: none;
                                    /*&.longInput {
                                        height: 253px;
                                    }*/
                                }
                                select {
                                    box-shadow: none;
                                    -webkit-box-shadow: none;
                                    -moz-box-shadow: none;
                                    color: #73788E;
                                    font-size: 14px;
                                    font-family: 'PT Sans';
                                    font-weight: 700;
                                    border-radius: 4px;
                                    -webkit-border-radius: 4px;
                                    -moz-border-radius: 4px;
                                    border: none;
                                    display: block;
                                    width: 100%;
                                    height: 38px;
                                    padding: 5px 35px 5px 15px;
                                    background: #F5F5FA url(../assets/images/down-arrow.png) no-repeat 95% 16px;
                                    background-size: 10px;
                                    box-sizing: border-box;
                                    outline: none;
                                    appearance: none;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    &:focus {
                                        color: #73788E;
                                    }
                                    &:focus::placeholder {
                                        color: #73788E;
                                    }
                                    &:focus:-ms-input-placeholder {
                                        color: #73788E;
                                    }
                                    &:focus::-ms-input-placeholder {
                                        color: #73788E;
                                    }
                                }
                                &.topAlign {
                                    align-items: start;
                                }
                                .pagination {
                                    padding: 0px;
                                    li {
                                        margin-right: 8px;
                                        height: 40px;
                                        flex: 0 40px;
                                        border-radius: 4px;
                                        margin-bottom: 0px;
                                        background-color: #F4F7F6;
                                        display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -moz-flex;
                                        display: -webkit-flex;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        a {
                                            color: #6A7587;
                                            font-size: 0.75rem;
                                            letter-spacing: 0;
                                            line-height: 40px;
                                            text-align: center;
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            min-width: 100px;
                                        }
                                        &.active {
                                            background: #6A7587;
                                            span {
                                                color: #fff;
                                                font-size: 0.75rem;
                                            }
                                        }
                                        &:hover {
                                            background: #6A7587;
                                            a {
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.additivesTabs-inner{
                    float: left;
                    width: 100%;
                    margin: 0;
                    .nav{
                        justify-content: left;
                        .nav-link{
                            font-size:18px;  
                            padding: 25px 30px; 
                            @media screen and (max-width:1680px) {
                                font-size:17px;                              
                            }
                            @media screen and (max-width:1600px) {
                                font-size:17px;       
                                padding: 25px 20px;                       
                            }                
                            @media screen and (max-width:1440px) {
                                font-size:16px;   
                                padding:20px 17px; 
                            }
                            @media screen and (max-width:1366px) {
                                font-size:14px; 
                                padding: 19px 16px;   
                            }
                            @media screen and (max-width:991px) {
                                font-size:18px; 
                                white-space: nowrap;  
                                min-width:auto;   
                            }                
                            &.active{
                                background:#F5F7FB;
                                font-size:18px; 
                                @media screen and (max-width:1680px) {
                                    font-size:17px;   
                                }                
                                @media screen and (max-width:1440px) {
                                    font-size:16px;   
                                }
                                @media screen and (max-width:1366px) {
                                    font-size:15px;   
                                } 
                                @media screen and (max-width:991px) {
                                    font-size:18px;   
                                }   
                            }
                        }
                    }        
                    .tab-content{
                        background: #F5F7FB;
                        border-radius: 0 16px 16px 16px;
                        padding: 40px 54px 60px;
                        box-shadow: none;
                        @media screen and (max-width:991px) {
                            padding: 40px 25px 60px;
                            border-radius: 0px 0px 16px 16px;
                        }
                        .tabsDetail-container{
                            .tabsListing{
                                display: -webkit-box;
                                display: -moz-box;
                                display: -ms-flexbox;
                                display: -moz-flex;
                                display: -webkit-flex;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                width: 100%;    
                                li{                                                          
                                    label{
                                        margin-bottom: 10px;             
                                    }                                           
                                    input[type="text"], 
                                    input[type="email"],  
                                    input[type="password"]{                            
                                        background-color: #fff;
                                        &:focus{                            
                                            color:#73788E; 
                                        }
                                        &:focus::placeholder{                            
                                            color:#73788E; 
                                        }
                                        &:focus:-ms-input-placeholder{                            
                                            color:#73788E; 
                                        }
                                        &:focus::-ms-input-placeholder{                            
                                            color:#73788E; 
                                        } 
                                    } 
                                    textarea{                            
                                        background: #fff;
                                    }                           
                                    &.disabledselect{
                                        .css-b62m3t-container{
                                            pointer-events: none;
                                            cursor: not-allowed;
                                            opacity: 0.7;
                                            border: 1px solid #efefef;
                                            border-radius: 5px;
                                        }
                                    }                                                                   
                                }
                            }    
                        }
                    }        
                }               
            }
            .internet-container{
                float:left;
                width:100%; 
                margin:30px 0;
                .internet-block{
                    float:left;
                    width:100%; 
                    margin-bottom: 35px;
                    .internet-list{                        
                        float:left;
                        width:100%;      
                        text-align:center;  
                        @media screen and (max-width: 991px) {
                            white-space: nowrap;
                        }
                        &:last-child {
                            margin-right: 0px;
                        }
                        li{
                            display: inline-block;
                            margin-right:15px;                                 
                            a {
                                border: none;                            
                                min-width: 95px;
                                background: #EBF0F7;
                                cursor: pointer;
                                border-radius: 80px;
                                -webkit-border-radius: 80px;
                                -moz-border-radius: 80px;
                                -ms-border-radius: 80px;
                                -o-border-radius: 80px;
                                height: 45px;
                                display: inline-block;
                                transition: none;
                                text-decoration: none;
                                color: #73788E;
                                font-size: 1rem;
                                font-weight: bold;
                                letter-spacing: 0.15px;
                                line-height: 41px;
                                position: relative;
                                text-align: center;
                                padding: 2px 20px 2px 20px;                            
                                color: #69778B;
                                @media screen and (max-width: 1440px) {
                                    font-size: 0.9rem;
                                }
                                @media screen and (max-width:1366px) {
                                    font-size: 0.8rem;
                                }
                                &:hover {
                                    color: #69778B;
                                }
                                &.active {
                                    padding: 2px 20px 2px 38px;
                                    color: #69778B;
                                    &:after {
                                        content: "";
                                        width: 17px;
                                        margin-left: 0;
                                        height: 17px;
                                        background: url(../assets/images/greentick.svg) no-repeat 0px 0px;
                                        background-size: 100%;
                                        left: 15px;
                                        top: 14px;
                                        position: absolute;
                                        transition: all 0.5s ease;
                                        -webkit-transition: all 0.5s ease;
                                        -moz-transition: all 0.5s ease;
                                        -ms-transition: all 0.5s ease;
                                        -o-transition: all 0.5s ease;
                                    }
                                }
                            }
                        }    
                    }
                }
                .internet-input{
                    width: 100%;
                    position: relative;
                    max-width: 900px;
                    margin: 0 auto;
                    display: table;
                    input[type="text"]{
                        border: none;                        
                        padding:0px 150px 0 0; 
                        border-bottom: 2px solid #E9E9E9;
                        border-radius: 0px;   
                        color:#69778B;
                        font-size:20px;    
                        @media screen and (max-width:1440px){                    
                            font-size:18px;    
                        }
                        @media screen and (max-width:1366px){                    
                            font-size:16px;    
                        }
                    }
                    .btn-custom{
                        position: absolute;
                        top:0px;
                        right:0px;
                        z-index:999; 
                        padding: 5px 30px;
                        height:56px;
                        @media screen and (max-width:1366px) {
                            height: 48px;
                        }
                    }
                }
            }
            .plantsTabs { 
                float: left;
                width: 100%;
                margin: 0px;    
                .nav {
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -moz-flex;
                    display: -webkit-flex;
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style: none;
                    justify-content: center;
                    border: none;
                    @media screen and (max-width: 991px) {
                        flex-wrap: inherit;
                        justify-content: normal;
                        overflow-x: auto;
                        -ms-overflow-style: none;
                        /* IE and Edge */
                        scrollbar-width: none;
                        /* Firefox */
                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                    .nav-link {
                        color: #69778B;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 20px;
                        text-align: center;
                        padding: 25px 50px;
                        border-radius: 16px 16px 0 0;
                        border: none;
                        @media screen and (max-width: 1440px) {
                            font-size: 14px;
                            line-height: 18px;
                            padding: 22px 45px;
                        }
                        @media screen and (max-width:1366px) {
                            padding: 22px 35px;
                        }
                        @media screen and (max-width:991px) {
                            min-width: 200px;
                            white-space: nowrap;
                        }
                        &.active {
                            font-weight: bold;
                        }
                    }
                }
                .tab-content {
                    background: #fff;
                    border-radius: 16px;
                    -webkit-border-radius: 16px;
                    -moz-border-radius: 16px;
                    float: left;
                    width: 100%;
                    padding: 56px 48px;
                    box-shadow:none;  
                    @media screen and (max-width: 991px) {
                        border-radius: 0 0 16px 16px;
                    }
                    .tabsDetail-container {
                        float: left;
                        width: 100%;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox; 
                        display: -moz-flex;
                        display: -webkit-flex;
                        display: flex;
                        .tabsListing {
                            display: -webkit-box;
                            display: -moz-box;
                            display: -ms-flexbox;
                            display: -moz-flex;
                            display: -webkit-flex;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            width: 100%;
                            li {
                                position: relative;
                                margin-bottom: 28px;
                                flex: 0 48%;
                                @media screen and (max-width: 991px) {
                                    flex: 0 100%;
                                }
                                &:last-child {
                                    margin-bottom: 0px;
                                }
                                label {
                                    color: #73788E;
                                    font-family: 'PT Sans';
                                    font-size: 12px;
                                    letter-spacing: 0;
                                    line-height: 20px;
                                    text-align: left;
                                    width: 100%;
                                    margin-bottom: 10px;
                                    strong {
                                        width: 30px;
                                        height: 30px;
                                        margin-left: 6px;
                                        line-height: 18px;
                                        cursor: pointer;
                                        position: absolute;
                                        right: 0;
                                        top: 0;
                                        img {
                                            width: 100%;
                                        }
                                        .autoHide {
                                            opacity: 1;
                                            animation: fadeIn 2s;
                                            animation-delay: 1s;
                                            animation-fill-mode: forwards;
                                            color: #6EB724;
                                        }
                                        @keyframes fadeIn {
                                            from {
                                                opacity: 1;
                                            }
                                            to {
                                                opacity: 0;
                                            }
                                        }
                                    }
                                }
                                input[type="text"],
                                input[type="email"],
                                input[type="password"] {
                                    width: 100%;
                                    height: 38px;
                                    box-shadow: none;
                                    -webkit-box-shadow: none;
                                    -moz-box-shadow: none;
                                    font-size: 12px;
                                    font-weight: bold;
                                    letter-spacing: 0;
                                    color: #73788E;
                                    line-height: 20px;
                                    border-radius: 4px;
                                    -webkit-border-radius: 4px;
                                    -moz-border-radius: 4px;
                                    border: none;
                                    display: block;
                                    padding: 5px 15px;
                                    box-sizing: border-box;
                                    background-color: #F5F5FA;
                                    outline: none;
                                    &:focus {
                                        color: #73788E;
                                    }
                                    &:focus::placeholder {
                                        color: #73788E;
                                    }
                                    &:focus:-ms-input-placeholder {
                                        color: #73788E;
                                    }
                                    &:focus::-ms-input-placeholder {
                                        color: #73788E;
                                    }
                                    &.disabledField {
                                        background: #efefef!important;
                                        border: 1px solid #ccc!important;
                                    }
                                }
                                textarea {
                                    box-shadow: none;
                                    -webkit-box-shadow: none;
                                    -moz-box-shadow: none;
                                    color: #73788E;
                                    font-size: 0.75rem;
                                    font-family: 'PT Sans';
                                    font-weight: 700;
                                    border-radius: 4px;
                                    -webkit-border-radius: 4px;
                                    -moz-border-radius: 4px;
                                    border: none;
                                    display: block;
                                    width: 100%;
                                    height: 93px;
                                    padding: 10px 15px;
                                    background: #F5F5FA;
                                    box-sizing: border-box;
                                    outline: none;
                                    resize: none;
                                    /*&.longInput {
                                        height: 253px;
                                    }*/
                                }
                                select {
                                    box-shadow: none;
                                    -webkit-box-shadow: none;
                                    -moz-box-shadow: none;
                                    color: #73788E;
                                    font-size: 14px;
                                    font-family: 'PT Sans';
                                    font-weight: 700;
                                    border-radius: 4px;
                                    -webkit-border-radius: 4px;
                                    -moz-border-radius: 4px;
                                    border: none;
                                    display: block;
                                    width: 100%;
                                    height: 38px;
                                    padding: 5px 35px 5px 15px;
                                    background: #F5F5FA url(../assets/images/down-arrow.png) no-repeat 95% 16px;
                                    background-size: 10px;
                                    box-sizing: border-box;
                                    outline: none;
                                    appearance: none;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    &:focus {
                                        color: #73788E;
                                    }
                                    &:focus::placeholder {
                                        color: #73788E;
                                    }
                                    &:focus:-ms-input-placeholder {
                                        color: #73788E;
                                    }
                                    &:focus::-ms-input-placeholder {
                                        color: #73788E;
                                    }
                                }
                                .upload-item{
                                    font-size: 14px;
                                    color: #707070;
                                    margin-bottom: 5px;
                                    display: inline-block;
                                    margin-right: 6px;
                                    border: 1px solid #ccc;                            
                                    border-radius: 6px;
                                    padding:5px 6px 5px 15px;
                                    span{
                                        width:22px;
                                        display: inline-block;
                                        margin-left:7px; 
                                        &:last-child{
                                            margin-left:4px; 
                                        }
                                        img {
                                            &.imgFirst {
                                                display: inline-block;
                                                opacity: 1;
                                            }

                                            &.imghover {
                                                display: none;
                                                opacity: 0;
                                            }
                                        } 
                                        &:hover{                                    
                                            img{
                                                &.imgFirst {
                                                    display:none;
                                                    opacity:0;
                                                }

                                                &.imghover {
                                                    display:inline-block;
                                                    opacity:1;
                                                }      
                                            }                                        
                                        }
                                                                   
                                    }
                                    
                                }
                                &.topAlign {
                                    align-items: start;
                                }
                                .pagination {
                                    padding: 0px;
                                    li {
                                        margin-right: 8px;
                                        height: 40px;
                                        flex: 0 40px;
                                        border-radius: 4px;
                                        margin-bottom: 0px;
                                        background-color: #F4F7F6;
                                        display: -webkit-box;
                                        display: -moz-box;
                                        display: -ms-flexbox;
                                        display: -moz-flex;
                                        display: -webkit-flex;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        a {
                                            color: #6A7587;
                                            font-size: 0.75rem;
                                            letter-spacing: 0;
                                            line-height: 40px;
                                            text-align: center;
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            min-width: 100px;
                                        }
                                        &.active {
                                            background: #6A7587;
                                            span {
                                                color: #fff;
                                                font-size: 0.75rem;
                                            }
                                        }
                                        &:hover {
                                            background: #6A7587;
                                            a {
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                                &.fullCol{
                                    flex: 0 100%;     
                                }
                            }
                        }
                    }
                }
                &.plantsModal-New{
                    margin-bottom:50px;
                    .nav{
                        .nav-link{
                            padding: 20px 30px;
                        }
                    }
                }
            }
            .additivesTabs-inner{

            } 
        }                     
    }
}        