.loginFooter {
    float: left;
    width: 100%;
    margin-top: 200px;
    text-align: center;
    @media screen and (max-width:1600px){
        margin-top:150px;
    }
    @media screen and (max-width:1440px){
        margin-top:140px;
    }
    @media screen and (max-width:1366px){
        margin-top:90px;
    }
    .logoBottom {
        width: 100%;
        text-align: center;
        max-width: 83px;
        margin: 0 auto;
        display: table;
        @media screen and (max-width:1366px){
            max-width:70px;
        }
        img {
            width: 100%;
        }
    }

    p {
        margin-top: 12px;
        font-weight: bold;
        color: #6A7587;
        font-size: 16px;
        margin-bottom: 0px;
        float: left;
        width: 100%;
        @media screen and (max-width:1366px){
            font-size: 14px;
        }
    }
}