.headerSection {
    float: left;
    width: 100%;
    padding: 15px 15px 15px;
   
    @media screen and (max-width:480px) { 
        padding: 15px 5px 15px;
    }

    .header-left {
        float: left;
        width: auto;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        align-items: center;

        .logo-wrapper {
            float: left;
            margin-right: 56px;
            max-width: 83px;

            @media screen and (max-width:1440px) {
                max-width: 70px;
                margin-right:30px;
            }

            @media screen and (max-width:1366px) {
                max-width: 65px;
            }

            @media screen and (max-width:768px) {
                max-width: 60px;
            }

            img {
                width: 100%;
            }
        }

        .nav-list {
            float: left;
            margin: 0 0 0 0px;

            @media screen and (max-width:480px) {
                display: none;
            }

            li {
                position: relative;
                transition: 0.48s;
                float: left;
                list-style: none;
                margin-right:10px;
                height:30px;
                align-items: center;
                display: flex;

                @media screen and (max-width:1440px) {
                    margin-right:10px;
                }

                @media screen and (max-width:1366px) {
                    margin-right:10px;
                }

                @media screen and (max-width:768px) {
                    margin-right: 20px;
                }

                a {
                    display: block;
                    line-height: 20px;
                    color: #6C7789;
                    text-decoration: none;
                    font-weight: 600;
                    font-size: 1.25rem;
                    position: relative;
                    padding: 0px 30px 0 0;

                    @media screen and (max-width:1440px) {
                        font-size: 1rem;
                        padding: 0px 22px 0 0;
                    }

                    @media screen and (max-width:768px) {
                        font-size: 1rem;
                        padding: 0px 20px 0 0;
                    }

                    &:hover {
                        color: #6EB724;
                    }

                    &:after {
                        content: "";
                        width: 25px;
                        margin-left: 0;
                        height: 25px;
                        background: url(../assets/images/leaf-shapes.svg) no-repeat 0px -1px;
                        position: absolute;
                        background-size: 100%;
                        right: 0px;
                        opacity: 0;
                        top: 0px;
                        visibility: hidden;
                        transition: all 0.5s ease;
                        -webkit-transition: all 0.5s ease;
                        -moz-transition: all 0.5s ease;
                        -ms-transition: all 0.5s ease;
                        -o-transition: all 0.5s ease;

                        @media screen and (max-width:1440px) {
                            width: 20px;
                            height: 20px;
                            top: 2px;
                        }

                        @media screen and (max-width:768px) {
                            width: 16px;
                            height: 16px;
                            top: 4px;
                        }
                    }
                }                                
                .subDropdown-Menu{
                    z-index: 99999;
                    position: absolute;                    
                    width: 240px;
                    border-radius: 12px;
                    -webkit-border-radius: 12px;
                    -moz-border-radius: 12px;
                    padding: 15px 20px;
                    left:-20px;
                    top: 100%;
                    background: #fff;
                    display: none;
                    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
                    @media screen and (max-width:1440px) {
                        width: 220px;
                    }
                    @media screen and (max-width:1366px) {
                        width: 200px;
                    }
                    li {
                        float: left;
                        width: 100%;
                        position: relative;
                        margin: 0px;
                        display: block;
                        height: auto;
                        a {
                            line-height:33px;
                            color: #6C7789;
                            text-decoration: none;
                            font-weight: 600;
                            font-size: 1.15rem;
                            position: relative;
                            padding: 0px 0px 0 0;

                            @media screen and (max-width:1440px) {
                                font-size: 1rem;
                            }

                            @media screen and (max-width:768px) {
                                font-size: 0.9rem;
                                padding: 0px 0px 0 0;
                            }                                                       
                        }
                        &:hover {
                            a {
                                color: #6EB724;
                                &:after {
                                    display:none;
                                }
                            }

                        }
                    }
                }                                    

                &:hover,
                &.active {
                    a {
                        color: #6EB724;

                        &:after {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    .subDropdown-Menu{
                        li{
                            a{
                                &:after {
                                    display:none;
                                }   
                            }
                        }
                    }
                }
                &:hover{
                    .subDropdown-Menu{
                        display:block;
                    }
                }

            }
        }
    }

    .rightColoum {
        margin-top: 13px;
        float: right;
      
        @media screen and (max-width: 768px) {
          margin-top: 11px;
        }
      
        @media screen and (max-width: 480px) {
          position: absolute;
          right: 15px;
          top: 15px;
        }
      
        .userInfo {
          float: left;
          margin-right: 0;
          margin-top: 0;
          position: relative;
          padding-bottom: 8px;
      
          @media screen and (max-width: 768px) {
            padding-bottom: 3px;
          }
      
          .showMenu {
            width: 180px;
            border: 1px solid #E6E8F0;
            background-color: #FFFFFF;
            box-shadow: 0 8px 16px 0 rgba(143, 149, 178, 0.15);
            z-index: 99999;
            position: absolute;
            display: none;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            padding: 20px;
            right: 0px;
            top: 100%;
      
            li {
              float: left;
              width: 100%;
              position: relative;
              list-style: none;
              margin-bottom: 18px;
      
              &:last-child {
                margin-bottom: 0px;
              }
      
              a {
                color: #6A7587;
                font-size: 0.875rem;
                letter-spacing: 0;
                line-height: 14px;
                position: relative;
      
                span {
                  width: 12px;
                  height: auto;
                  border-radius: 0px;
                  overflow: visible;
                  float: left;
                  margin-right: 10px;
      
                  img {
                    &.imgFirst {
                      display: inline-block;
                      opacity: 1;
                    }
      
                    &.imghover {
                      display: none;
                      opacity: 0;
                    }
                  }
                }
      
                &:hover {
                  color: #6EB724;
      
                  img {
                    &.imgFirst {
                      display: none;
                      opacity: 0;
                    }
      
                    &.imghover {
                      display: inline-block;
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
      
          a {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
            align-items: center;
      
            strong {
              margin-right: 10px;
              font-weight: 700;
              font-size: 1rem;
              color: #6A7587;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
      
              @media screen and (max-width: 1440px) {
                font-size: 0.9rem;
              }
      
              @media screen and (max-width: 991px) {
                font-size: 0.8rem;
                margin-right: 7px;
              }
            }
      
            label {
              float: left;
              margin-left: 5px;
              line-height: 33px;
              cursor: pointer;
      
              img {
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
              }
            }
      
            span {
              width: 48px;
              height: 48px;
              border-radius: 10px;
              overflow: hidden;
              float: left;
      
              @media screen and (max-width: 991px) {
                width: 40px;
                height: 40px;
              }
            }
          }
      
          &:hover {
            .showMenu {
              display: block;
            }
      
            a {
              label {
                img {
                  transform: rotate(180deg);
                  -webkit-transition: all 0.3s ease;
                  -moz-transition: all 0.3s ease;
                  -ms-transition: all 0.3s ease;
                  -o-transition: all 0.3s ease;
                  transition: all 0.3s ease;
                }
              }
            }
          }
        }
      }
      
}

.top-breadcrumb-header{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom:20px;
    float:left;
    width:100%;             
    .breadcrumb-div {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        padding: 0 0;
        list-style: none;
        li {
            float: left;
            margin-right: 30px;
            color: #677589;            
            letter-spacing: 0;
            position: relative;
            line-height: 20px;
            color: #262726;
            font-size: 1.938rem;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 32px;
            margin: 0 20px 0 0;
            @media screen and (max-width:1440px){
                font-size:1.550rem;    
            }
            @media screen and (max-width:1366px){
                font-size:1.440rem;    
            }
            @media screen and (max-width:768px){
                font-size:1.3rem;    
            }

            a {
                color: #262726;
                font-size: 1.938rem;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 32px;
                margin: 0;
                @media screen and (max-width:1440px){
                    font-size:1.75rem;    
                }
                @media screen and (max-width:1366px){
                    font-size:1.60rem;    
                }
                @media screen and (max-width:768px){
                    font-size:1.3rem;    
                }

                img {
                    margin-top: -6px;
                    width: 30px;
                    @media screen and (max-width:1440px){
                        width:26px;
                    }
                    @media screen and (max-width:1366px){
                        width:24px;
                    }
                }
            }

            &:after {
                content: "";
                width:14px;
                margin-left: 0;
                height: 14px;
                background: url(../assets/images/right-icon.svg) no-repeat;
                position: absolute;
                right: -21px;
                top: 12px;
            }

            &:last-child {
                margin-right: 0px;

                &:after {
                    display: none;
                }
            }
        }
    }
    .rightPanel {
        padding: 0px;
        display: flex;
        align-items: center;
        gap: 20px;

        label {
            color: #6C7789;
            font-size: 1.25rem;
            font-weight: bold;
            letter-spacing: 0;
            margin-right: 7px;
            margin-bottom: 0px;
            line-height: 26px;
        }

        .btnError {
            height: 40px;
            width: 104px;
            color: #FF6D3A;
            font-family: 'PT Sans';
            font-size: 1rem;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            border: none;
            border-radius: 8px;
            background-color: #FFF7F5;
        }
        .radioBtn-Type{
            margin-top: 0;
            float: right;              
            .radio{  
                display: inline-block;              
                p{
                    display:inline-block; 
                    margin-right:20px;  
                    margin-bottom:0px;        
                    @media screen and (max-width:1440px){
                        margin-right:15px;  
                    }             
                    &:last-child{
                        margin-right:0px; 
                    }
                }    
                label{
                    display: inline-block; 
                    position: relative;                               
                    font-size: 16px;                      
                    font-weight:600;
                    color: #69778B; 
                    line-height: 25px; 
                    margin: 0;    
                    cursor:pointer;  
                    @media screen and (max-width:1440px){
                        font-size:14px;   
                        line-height: 20px;   
                    }          
                    &:before{
                        content: "";
                        display: inline-block;                   
                        width:24px;
                        height:24px;                                  
                        border:2px solid #73788e;
                        background-color: #fff;    
                        border-radius:100%;
                        -webkit-border-radius:100%;              
                        -moz-border-radius:100%;              
                        float: left;
                        margin-right:7px;
                        cursor: pointer;
                        @media screen and (max-width:1440px){
                            width:20px;    
                            margin-right: 5px;
                            height:20px;  
                        } 
                    }
                    &:after{               
                        display: inline-block;                  
                        width:24px;
                        line-height: 23px; 
                        cursor: pointer;
                        height:24px; 
                        float: left;                  
                        border:2px solid #73788e;
                        margin-right:7px;
                        border-radius:100%;
                        -webkit-border-radius:100%;              
                        -moz-border-radius:100%;  
                        @media screen and (max-width:1440px){
                            width:20px;    
                            margin-right: 5px;
                            height:20px;  
                        }                       
                    }  
                }      
                input[type="radio"]{
                    opacity: 0;
                    display: none;                
                }
                input[type="radio"]:checked + label::after{
                    background:#6EB724 url(../assets/images/tick-white.svg) no-repeat;
                    content:"";                   
                    border-color:#6EB724;  
                    background-position: center;
                    background-size: 16px;
                }
                input[type="radio"]:checked + label::before {
                  display:none;    
                }
            }    
        } 
        .action-btn {
            padding: 0px;

            li {
                float: left;
                margin-right: 5px;

                &:last-child {
                    margin-right: 0px;
                }

                a {
                    color: #7FB541;
                    font-size: 1rem;
                    letter-spacing: 0;
                    line-height: 29px;
                    text-align: center;
                    display: inline-block;
                    padding: 5px 20px;
                    height: 40px;
                    @media screen and (max-width:1440px){
                        font-size: 0.9rem;
                    }
                    @media screen and (max-width:1366px){
                        font-size: 0.8rem;
                    }
                    &:hover,
                    &.active {
                        border-radius: 4px;
                        background-color: #7FB541;
                        color: #fff;
                    }
                }
                .btnNormal{
                    color: #7FB541;
                    font-size: 1rem;
                    letter-spacing: 0;
                    line-height: 29px;
                    text-align: center;
                    display: inline-block;
                    padding: 5px 20px;
                    height: 40px;
                    border: none;
                    background: transparent;
                    @media screen and (max-width:1440px){
                        font-size: 0.9rem;
                        line-height: 26px;
                        height: 36px;
                    }
                    @media screen and (max-width:1366px){
                        font-size: 0.8rem;
                    }
                    &:hover,
                    &.active {
                        border-radius: 4px;
                        background-color: #7FB541;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.fixedBottom-bar {
    margin-top: 0;
    margin-bottom: 0;
    height: 100px;
    border: 1px solid #CDCFD3;
    background: linear-gradient(0deg, #F5F5FA 0%, rgba(254, 254, 255, 0.99) 100%, #FFFFFF 100%);
    box-shadow: 0 5px 64px -32px rgba(15, 15, 15, 0.55);
    position: sticky;
    bottom: 0;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    @media screen and (max-width:991px) {
        display: inline-block;
        padding: 15px 50px;
        height: 220px;
    }

    h3 {
        margin: 0px 0 0 40px;
        color: #262726;
        font-size: 1.25rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 26px;
        text-align: center;
        position: absolute;
        left: 0;

        @media screen and (max-width:1440px) {
            font-size: 1.15rem;
        }

        @media screen and (max-width:1366px) {
            font-size: 1.05rem;
        }

        @media screen and (max-width:991px) {
            position: static;
            margin: 0px 0px 10px 0px;
            text-align: left;
        }
    }
    .btnSectionCenter{
        margin:0px;
        display: flex;
        justify-content:space-between;
        align-items: center;
        a{
            margin:0 5px;
            &.btn-center{
                font-size: 16px;
                text-align: center;
                padding: 8px 20px;
                border-radius: 4px;
                background-color: #7FB541;
                color: #fff;
                min-width: 250px;
                max-width: 250px;
                line-height: 19px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-width:1440px) {
                    font-size:15px;
                }
                @media screen and (max-width:1366px) {
                    font-size:14px;
                }
            }   
        }
    }
    .links-bottom {
        margin: 0px;

        @media screen and (max-width:991px) {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
            flex-flow: wrap;
            gap: 20px;
        }

        li {
            float: left;
            margin-right: 30px;
            cursor: pointer;
            position: relative;
            height: 100px;
            align-items: center;
            display: flex;

            @media screen and (max-width:991px) {
                height: auto;
                flex: 0 48.5%;
                display: inherit;
                margin-right: 0;
            }

            &:after {
                position: absolute;
                height: 15px;
                content: "";
                left: 0px;
                width: 100%;
                top: -10px;
            }

            strong {
                padding: 0px;
                margin-right: 5px;
                position: absolute;
                left: 0;

                img {
                    width: 24px;

                    &.imgFirst {
                        display: inline-block;
                        opacity: 1;
                    }

                    &.imghover {
                        display: none;
                        opacity: 0;
                    }
                }
            }

            span {
                margin-left: 32px;
                float: left;

                &.statusHeading {
                    float: none;
                    color: #6C7789;
                    font-size: 1rem;
                    cursor: pointer;
                    letter-spacing: 0;
                    line-height: 20px;
                    margin: 0px;
                    display: block;
                    font-weight: 600;
                }

                &.statusValue {
                    float: none;
                    margin: 0px;
                    color: #6C7789;
                    font-size: 0.95rem;
                    cursor: pointer;
                    letter-spacing: 0;
                    line-height: 20px;
                    display: block;
                    max-width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &.dateClose {
                        padding-right: 17px;
                        position: relative;

                        img {
                            position: absolute;
                            top: 7px;
                            right: 0px;
                            height: 8px;
                            width: 8px;
                            z-index: 9999;
                        }
                    }
                }
            }

            .dropDown-Menu {
                width: 280px;
                background-color: #FFFFFF;
                border: 1px solid #CDCFD3;
                background: linear-gradient(0deg, #F5F5FA 0%, rgba(254, 254, 255, 0.99) 100%, #FFFFFF 100%);
                z-index: 99999;
                position: absolute;
                visibility: hidden;
                opacity: 0;
                border-radius: 8px 8px 0 0;
                -webkit-border-radius: 8px 8px 0 0;
                -moz-border-radius: 8px 8px 0 0;
                padding:17px 15px;
                left: 50%;
                bottom: 100px;
                transform: translate(-50%, 0px);
                -webkit-transform: translate(-50%, 0px);
                -moz-transform: translate(-50%, 0px);
                border-bottom: none;
                
                @media screen and (max-width:991px) {
                    width: 100%;
                }

                &.flexWidth {
                    width: auto;
                    overflow: visible;
                }

                &:after,
                &:before {
                    top: 100%;
                    left: 50%;
                    border: solid transparent;
                    content: "";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                &:after {
                    border-color: rgba(245, 245, 250, 0);
                    border-top-color: #f5f5fa;
                    border-width: 7px;
                    margin-left: -7px;
                }

                &:before {
                    border-color: rgba(205, 207, 211, 0);
                    border-top-color: #CDCFD3;
                    border-width: 8px;
                    margin-left: -8px;
                }
                .bottom-search{
                    position:relative;
                    float:left;
                    width:100%;   
                    margin-bottom: 15px;                 
                    input[type="text"]{                    
                        height:48px;
                        color: #707070;
                        border-radius:4px;
                        -webkit-border-radius:4px;
                        -moz-border-radius:4px;    
                        padding:3px 42px 3px 10px;                                     
                    }    
                    .btn-search{
                        position: absolute;
                        top: 8px;
                        right: 8px;
                        height: 32px;
                        width: 32px;
                        border-radius: 4px;
                        background-color: #7FB541;
                        border: none;
                        line-height: 13px;
                    }
                }
                .dropDown-Menu-scroll{
                    height:270px;
                    overflow: auto;
                    float: left;
                    width: 100%;
                    .optionType {
                        float: left;
                        width: 100%;
                        position: relative;
                        list-style: none;
                        margin-bottom: 10px;
                        padding: 0;
                        margin-right: 0;
                        height: auto;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        a {
                            .radio-panel {
                                label {
                                    color: #6C7789;
                                    font-size: 0.875rem;
                                    font-weight: bold;
                                    letter-spacing: 0;
                                    line-height: 19px;
                                    cursor: pointer;
                                }
                            }
                        }

                    }
                    &.dropdownBig {
                        width: 393px;
                        padding: 15px;
                        overflow: visible;
                        .optionType {
                            .textBlock {
                                background: #fff;
                                border: none;
                                height: 160px;
                                border-radius: 4px;
                                width: 100%;

                                textarea {
                                    color: #73788E;
                                    font-family: "PT Sans";
                                    font-size: 12px;
                                    letter-spacing: 0;
                                    line-height: 15px;
                                    background: #fff;
                                    border: none;
                                    height: 110px;
                                    resize: none;
                                    padding: 13px 13px 0px 13px;
                                }

                                .btnSave {
                                    height: auto;
                                    width: auto;
                                    color: #FFFFFF;
                                    font-size: 0.75rem;
                                    font-weight: bold;
                                    letter-spacing: 0;
                                    text-align: center;
                                    display: inline-block;
                                    border: none;
                                    position: absolute;
                                    bottom: 13px;
                                    right: 17px;
                                    padding: 5px 18px;
                                    border-radius: 4px;
                                    background-color: #6A7587;
                                }
                            }
                        }
                    }
                }                    
            }

            &:nth-child(2) {
                .dropDown-Menu {
                    .dropDown-Menu-scroll{
                        max-height: 320px;
                        overflow: auto;
                    }    
                }
            }

            &:hover {
                .dropDown-Menu {                    
                    opacity: 1;
                    visibility: visible;                      
                }

                span {
                    color: #6EB724;
                }

                strong {
                    img {
                        &.imgFirst {
                            display: none;
                            opacity: 0;
                        }

                        &.imghover {
                            display: inline-block;
                            opacity: 1;
                        }
                    }
                }
            }

            &.active {
                span {
                    color: #6EB724;
                }

                strong {
                    img {
                        &.imgFirst {
                            display: none;
                            opacity: 0;
                        }

                        &.imghover {
                            display: inline-block;
                            opacity: 1;
                        }
                    }
                }
            }

            &:nth-child(1) {
                @media screen and (max-width:991px) {
                    .dropDown-Menu {
                        .dropDown-Menu-scroll{
                            bottom: 92px;
                        }
                    }
                }
            }

            &:nth-child(2) {
                @media screen and (max-width:991px) {
                    .dropDown-Menu {
                        .dropDown-Menu-scroll{
                            bottom: 92px;
                        }
                    }
                }
            }

            &:nth-child(3) {
                @media screen and (max-width:991px) {
                    .dropDown-Menu {
                        .dropDown-Menu-scroll{
                            bottom: 152px;
                        }
                    }
                }
            }

            &:nth-child(4) {
                @media screen and (max-width:991px) {
                    .dropDown-Menu {
                        .dropDown-Menu-scroll{
                            bottom: 152px;
                        }
                    }
                }
            }
        }
    }

    .btnSection-Fixed {
        padding: 0px;
        margin: 0px 40px 0 0;
        position: absolute;
        right: 0;

        @media screen and (max-width:991px) {
            position: static;
            margin: 10px 0 0 0;
            text-align: right;
        }

        a {
            color: #fff;
            font-size: 1rem;
            letter-spacing: 0;
            line-height: 29px;
            text-align: center;
            display: inline-block;
            padding: 5px 20px;
            height: 40px;
            border-radius: 4px;
            width: 150px;
            background: #7FB541;
            margin-left:10px;
        }
    }
}