.additiveDetails-tabs{
    float:left;
    margin-bottom:20px;		
    width:100%;   
    position: relative;
    .radioBtn-Type{
        margin-top: 0;
        float: right;              
        .radio{  
            display: inline-block;              
            p{
                display:inline-block; 
                margin-right:20px;  
                margin-bottom:0px;        
                @media screen and (max-width:1440px){
                    margin-right:15px;  
                }             
                &:last-child{
                    margin-right:0px; 
                }
            }    
            label{
                display: inline-block; 
                position: relative;                               
                font-size: 16px;                      
                font-weight:600;
                color: #69778B; 
                line-height: 25px; 
                margin: 0;    
                cursor:pointer;  
                @media screen and (max-width:1440px){
                    font-size:14px;   
                    line-height: 20px;   
                }          
                &:before{
                    content: "";
                    display: inline-block;                   
                    width:24px;
                    height:24px;                                  
                    border:2px solid #73788e;
                    background-color: #fff;    
                    border-radius:100%;
                    -webkit-border-radius:100%;              
                    -moz-border-radius:100%;              
                    float: left;
                    margin-right:7px;
                    cursor: pointer;
                    @media screen and (max-width:1440px){
                        width:20px;    
                        margin-right: 5px;
                        height:20px;  
                    } 
                }
                &:after{               
                    display: inline-block;                  
                    width:24px;
                    line-height: 23px; 
                    cursor: pointer;
                    height:24px; 
                    float: left;                  
                    border:2px solid #73788e;
                    margin-right:7px;
                    border-radius:100%;
                    -webkit-border-radius:100%;              
                    -moz-border-radius:100%;  
                    @media screen and (max-width:1440px){
                        width:20px;    
                        margin-right: 5px;
                        height:20px;  
                    }                       
                }  
            }      
            input[type="radio"]{
                opacity: 0;
                display: none;                
            }
            input[type="radio"]:checked + label::after{
                background:#6EB724 url(../assets/images/tick-white.svg) no-repeat;
                content:"";                   
                border-color:#6EB724;  
                background-position: center;
                background-size: 16px;
            }
            input[type="radio"]:checked + label::before {
              display:none;    
            }
        }    
    }                
} 
.additivesContanier{
    margin-bottom:50px;
    .add-functionalities{
        margin: 40px 0 40px;
        .small-title{
            margin-bottom: 30px;      
        }   
    }    
    .plantsTabs-container{
        margin-top: 0;
        margin-bottom: 30px;
        padding: 45px 58px 58px 58px;  
        .plantsTabs-container{
            padding:0px;
            .plantsListing{
                margin: 0px 0 0;
                padding: 0;
                &.plantsInner{
                    top:82px;
                }
            }
            .plantsTabs {
                float: left;
                width: 100%;
                margin: 72px 0 0px;
                padding: 0 15px;
                @media screen and (max-width:1440px){
                    margin:62px 0 0px;
                }
                @media screen and (max-width:1366px){
                    margin:52px 0 0px;
                }
            }

        } 
        .table-title {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
            flex-flow: wrap;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;
            @media screen and (max-width:1366px) {
                margin-bottom: 20px;
            }
            &.btnRight{
                justify-content: flex-end;      
            }
            h3{
                color: #262726;
                font-size: 1.6rem;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 32px;
                margin: 0;
                @media screen and (max-width:1600px){
                    font-size: 1.5rem;
                }
                @media screen and (max-width:1440px){
                    font-size: 1.4rem;
                }
                a{
                    color: #7FB541  ; 
                    &:hover{
                        color:#7FB541; 
                    }
                }
            }
            .rightbtn-Section{
                padding: 0px;
                .action-btn{
                    padding: 0px;
                    li {
                        float: left;
                        margin-right: 5px;
                        &:last-child {
                            margin-right: 0px;
                        }
                        a {
                            color: #7FB541;
                            font-size: 1rem;
                            letter-spacing: 0;
                            line-height: 29px;
                            text-align: center;
                            display: inline-block;
                            padding: 5px 20px;
                            height: 40px;
                            @media screen and (max-width:1440px){
                                font-size:0.9rem;
                            }
                            &:hover,
                            &.active {
                                border-radius: 4px;
                                background-color: #7FB541;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }     
        .plantsListing-block{
            float:left;
            width:100%;              
            margin-top: 20px;
            .block-Section{
                float:left;
                width:100%;  
                margin-bottom:48px;
                h3{
                    color: #262726;
                    font-size: 1rem;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 32px;
                    margin: 0 0 10px;  
                    float:left;
                    width:100%;                
                }
                .plantsListing{
                    margin: 0;
                    padding: 0;       
                }
            }
            .plantsTabs {
                float: left;
                width: 100%;
                margin: 0;
                .rightbtn-Section{
                    padding: 0px;
                    float: right;
                    margin-top: 50px;
                    width: 100%;
                    text-align: right;                    
                    .action-btn{
                        padding:0px;
                        display:inline-block;     
                        li{
                            float:left;
                            margin-right:5px;
                            &:last-child{
                                margin-right:0px;
                            }
                            a{
                                color: #7FB541;
                                font-size: 1rem;
                                letter-spacing: 0;
                                line-height: 29px;
                                text-align: center;
                                display: inline-block;
                                padding: 5px 20px;
                                height: 40px;
                                &:hover, &.active{
                                    border-radius: 4px;
                                    background-color: #7FB541;
                                    color:#fff;
                                }
                            }
                        }
                    }
                } 
            }
        } 
        .plantsParts-container{
            .add-functionalities{
                margin:0px;  
                .formListing-block{
                    .formSection-New{
                        .formListing{
                            li{
                                strong{
                                    .css-1s2u09g-control, .css-1pahdxg-control{
                                        background-color: #fff!important;
                                    }
                                    /*.css-26l3qy-menu{                                        
                                        border: 1px solid #fff!important;  
                                    }*/
                                    textarea{
                                        background-color: #fff!important;
                                    }
                                    input[type="text"], input[type="number"], input[type="password"]{
                                        background-color: #fff!important;
                                    }
                                }   
                            }
                        }
                    }
                }
            }
        }
        &.additive-inner-tabs{
            padding:0px;
        }
        .table-wrapper{
            .table-formating{
                &.tableSmall{
                    width: 100%;
                    thead{
                        tr{
                            th{
                                &:first-child{
                                    padding: 10px 30px;
                                    width:auto;
                                }         
                            }
                        } 
                    }
                    tbody{
                        tr{
                            td{
                                &:first-child{
                                    text-align:left;
                                    padding: 10px 30px;
                                } 
                                border-bottom:1px solid rgba(233,233,233,0.4);        
                            }
                        } 
                    } 
                }
            }    
        } 
    }
} 
.subHeading{
    float: left;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 20px;

    h2{
        color: #262726;            
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        margin-right: 15px;
        margin-bottom: 0px;
        line-height: 26px;

    }
    &.download-Item{
        position:relative;    
        .results-right-side {
            padding: 0px;
            position: absolute;
            right:0px;
            top: 0px;            
            .rightOption {
                float: left;
                width: 100%;
                text-align: right;
                margin: 0;
                align-items: center;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                gap: 20px;
                li {
                    float: left;
                    margin-right: 5px;
                    position: relative;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -moz-flex;
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;

                    &:last-child {
                        margin-right: 0px;
                    }

                    a {
                        color: #6A7587;
                        font-size:18px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 19px;
                        display:flex; 
                        align-items: center;
                        span {
                            margin: 0 6px;
                            display: inline-block;
                            color: #6A7587;
                            font-size: 0.875rem;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 19px;
                            img {
                                &.imgFirst {
                                    display: inline-block;
                                    opacity: 1;
                                }

                                &.imghover {
                                    display: none;
                                    opacity: 0;
                                }
                            }
                        }
                        &.downloadIcon{
                            span{
                                width:30px;   
                            }
                        }
                        &:hover,
                        &.active {
                            span {
                                img {
                                    &.imgFirst {
                                        display: none;
                                        opacity: 0;
                                    }

                                    &.imghover {
                                        display: inline-block;
                                        opacity: 1;
                                    }
                                }
                            }
                            color:#7FB541;   
                        }
                    }                                       
                }
            }
        }
    }    
}    
.plantReplacement-Block{
    margin-top:25px;
    margin-bottom:45px;
    float: left;
    width: 100%;    
    .filterSection{
        padding:0px; 
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        h2{
            color: #262726;            
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            margin-right: 15px;
            margin-bottom: 0px;
            line-height: 26px;
        }
        .main-search{                
            position:relative;   
            width:450px;
            height:48px;   
            @media screen and (max-width:1440px) {
                width:430px;
            }
            @media screen and (max-width:1366px) {
                width:410px;
            }
            input[type="text"]{
                width:450px;
                height:44px;  
                color: #6C7789;
                font-family: 'PT Sans';
                font-size: 1rem;
                letter-spacing: 0;
                line-height: 27px;       
                font-weight: 400;
                border-radius:8px;
                @media screen and (max-width:1440px) {
                    width:430px;
                }
                @media screen and (max-width:1366px) {
                    width:410px;
                }
            }
            .css-1s2u09g-control, .css-1pahdxg-control, .css-13cymwt-control{
                min-height: 48px !important;
                height: 48px !important; 
                color: #6C7789!important;
                background:#fff!important;
                border: 2px solid #E9E9E9!important;
                padding:0px 0px!important;
            }
            .css-6j8wv5-Input{
                height: 44px !important; 
            }
            .css-14el2xx-placeholder {
                margin: 0px !important;
                height: 42px;
                line-height: 43px;
            }
            .css-tj5bde-Svg{
                display:none;
            }
            .css-1pndypt-Input{
                margin:0px!important;
                padding:0px!important;
                height: 44px !important; 
                color: #6C7789!important;
            }
            .css-319lph-ValueContainer{
                height: 44px !important; 
                font-size: 1rem !important;
                font-weight: 400;
            }
            button{
                position: absolute;
                top:8px;
                right:8px;
                height: 32px;
                width: 32px;
                border-radius: 4px;
                background-color: #7FB541;
                border: none;
                line-height: 13px;
                svg{
                    margin-top:0px;  
                }
                &.closeBtn{
                    right: 46px;
                    background: none;
                    width: 26px;
                    top: 8px;
                    border-radius: 0px;       
                }
            } 
            .filterDropdown{
                position: absolute;
                width: 100%;
                padding: 10px 15px;
                background: #fff;
                border: 2px solid #E9E9E9;
                border-radius: 0 0 8px 8px;
                -webkit-border-radius: 0 0 8px 8px;
                -moz-border-radius: 0 0 8px 8px;
                border-top: none;
                z-index: 9999;
                top: 42px;   
                li{
                    display:block;
                    a{
                        color: #6C7789;                          
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 37px;
                        display:block;
                        &:hover{
                            color:#7FB541;
                        }
                    }
                }
            }    
        }
        .categorySelect{
            padding:0px;
            margin-left:15px; 
            select{
                width: 100%;
                min-width:231px;   
                font-size: 1rem;
                font-weight: 400;
                color: #707070;
                height: 48px;
                border-radius: 8px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
            } 
        }
        .filterBtn {
            position: relative;
            margin-left: 16px;
            @media screen and (max-width:480px) {
                margin-left:0px;
            }
            button {
                background: #fff;
                line-height: 13px;
                height: 48px;
                width: 105px;
                border: 2px solid #E9E9E9;
                border-radius: 8px;
                position: relative;

                @media screen and (max-width:1440px) {
                    height: 44px;
                }

                label {
                    color: #6C7789;
                    font-size: 1rem;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 27px;
                    cursor: pointer;

                    @media screen and (max-width:1440px) {
                        font-size: 0.938rem;
                    }
                }

                span {
                    display: inline-block;
                    margin-right: 5px;

                    img {
                        &.imgFirst {
                            display: inline-block;
                            opacity: 1;
                        }

                        &.imghover {
                            display: none;
                            opacity: 0;
                        }
                    }
                }

                .clearFilter {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    z-index:0;
                }

                &:hover {
                    border-color: #7FB541;

                    label {
                        color: #7FB541;
                    }

                    span {
                        img {
                            &.imgFirst {
                                display: none;
                                opacity: 0;
                            }

                            &.imghover {
                                display: inline-block;
                                opacity: 1;
                            }
                        }
                    }
                }

                &.active {
                    border-color: #7FB541;
                    background-color: #F9F9F9;

                    label {
                        color: #7FB541;
                    }

                    span {
                        img {
                            &.imgFirst {
                                display: none;
                                opacity: 0;
                            }

                            &.imghover {
                                display: inline-block;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .totalResults {
            margin-left: 16px;
            @media screen and (max-width:480px){
                position: absolute;
                bottom: 6px;
                right: 0;    
            }
            span {
                color: #262726;
                font-size: 1.563rem;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 32px;

                @media screen and (max-width:1440px) {
                    font-size: 1.375rem;
                }

                @media screen and (max-width:1366px) {
                    font-size: 1.25rem;
                }
            }
        }
    }  
    .additive-Pagination-right-side{
        padding: 0px;
        @media screen and (max-width:1023px){
            margin-top:10px;
        }
        @media screen and (max-width:480px){
            margin-top:20px;
        }
        .rightOption {
            float: left;
            width: 100%;
            text-align: right;
            margin: 0;
            align-items: center;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;

            li {
                float: left;
                margin-right: 5px;
                position: relative;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                align-items: center;

                &:last-child {
                    margin-right: 0px;
                }

                a {
                    display: inline-block;

                    span {
                        img {
                            &.imgFirst {
                                display: inline-block;
                                opacity: 1;
                            }

                            &.imghover {
                                display: none;
                                opacity: 0;
                            }
                        }
                    }

                    &:hover,
                    &.active {
                        span {
                            img {
                                &.imgFirst {
                                    display: none;
                                    opacity: 0;
                                }

                                &.imghover {
                                    display: inline-block;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

                .user-image-timeline {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    top: 60px;
                    left: 50%;
                    width: 344px;
                    border-radius: 10px;
                    z-index: 9999;
                    transform: translate(-50%, -0%);
                    border: 1px solid #CDCFD3;
                    background: linear-gradient(0deg, #F5F5FA 0%, rgba(254, 254, 255, 0.99) 100%, #FFFFFF 100%);
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                    -o-transition: all 0.3s ease;
                    transition: all 0.3s ease;

                    li {
                        width: 100%;

                        &.item-timeline {
                            &:nth-child(even) {
                                &:before {
                                    position: absolute;
                                    top: -7px;
                                    left: 167px;
                                    display: inline-block;
                                    border-top: 1px solid #CDCFD3;
                                    border-right: 1px solid #CDCFD3;
                                    width: 13px;
                                    height: 13px;
                                    transform: rotate(-45deg);
                                    -webkit-transform: rotate(-45deg);
                                    -moz-transform: rotate(-45deg);
                                    background: white;
                                    z-index: 1;
                                    content: '';

                                    @media screen and (max-width:1600px) {
                                        width: 10px;
                                        height: 10px;
                                        top: -5px;
                                    }

                                    @media screen and (max-width:1140px) {
                                        width: 15px;
                                        height: 15px;
                                        top: -8px;
                                    }

                                    @media screen and (max-width:480px) {
                                        right: 90px;
                                    }
                                }
                            }

                            &:nth-child(odd) {
                                &:before {
                                    position: absolute;
                                    top: 50px;
                                    left: 8px;
                                    display: inline-block;
                                    border-top: 12px solid transparent;
                                    border-right: 12px solid #fff;
                                    border-bottom: 12px solid transparent;
                                    border-right-color: #808080;
                                    content: '';
                                }
                            }
                        }

                        .inner-content {
                            margin: 0px;
                            padding: 20px;
                            float: left;
                            width: 100%;

                            .closeUpload {
                                display: block;
                                width: 26px;
                                right: 9px;
                                position: absolute;
                                z-index: 99999;
                                top: 9px;

                                @media screen and (max-width:1600px) {
                                    width: 20px;
                                    right: 9px;
                                    top: 7px;
                                }

                                img {
                                    @media screen and (max-width:1600px) {
                                        width: 20px;
                                    }

                                    @media screen and (max-width:1280px) {
                                        width: 18px;
                                    }
                                }
                            }

                            .imageDropper {
                                border-radius: 0;
                                float: left;
                                border: 0px;
                                text-align: center;
                                margin: 0px 0;
                                width: 100%;

                                .dragDrop-img {
                                    width: 100%;
                                    float: left;
                                    position: relative;
                                    display: block !important;

                                    .drop-uploadSec {
                                        width: 100%;
                                        background: #fff;
                                        border: none;
                                        border-radius: 10px;
                                        height: auto;
                                        padding: 0;
                                        display: block !important;

                                        .dragImg-new {
                                            width: 100%;
                                            display: block;
                                            margin-top: 15px;

                                            @media screen and (max-width:1280px) {
                                                margin-top: 10px;
                                            }

                                            img {
                                                @media screen and (max-width:1600px) {
                                                    width: 90px;
                                                }

                                                @media screen and (max-width:1280px) {
                                                    width: 80px;
                                                }
                                            }
                                        }

                                        .drop-uploadText {
                                            width: 100%;
                                            float: left;
                                            text-align: center;
                                            opacity: 1;
                                            line-height: 18px;
                                            border: 1px dashed #979797;
                                            border-radius: 8px;
                                            background-color: #FFFFFF;
                                            height: 118px;
                                            display: -webkit-box;
                                            display: -moz-box;
                                            display: -ms-flexbox;
                                            display: -moz-flex;
                                            display: -webkit-flex;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            color: #6C7789;
                                            font-size: 0.75rem;
                                            letter-spacing: 0;
                                            line-height: 19px;
                                            text-align: center;

                                            @media screen and (max-width:1440px) {
                                                height: 98px;
                                            }
                                        }

                                        .drop-uploadBtn-Sec {
                                            width: 100%;
                                            float: left;
                                            text-align: center;

                                            ul {
                                                li {
                                                    margin: 0px;
                                                    text-align: center;
                                                    width: 100%;
                                                    float: left;

                                                    .imageDropperNew {
                                                        margin: 15px 0px;
                                                        float: left;
                                                        width: 100%;

                                                        @media screen and (max-width:1440px) {
                                                            margin: 10px 0px;
                                                        }
                                                    }

                                                    a {
                                                        border: none;
                                                        width: 150px;
                                                        height: 40px;
                                                        padding: 0;
                                                        border-radius: 4px;
                                                        line-height: 40px;
                                                        background: #7FB541;

                                                        @media screen and (max-width:1440px) {
                                                            width: 140px;
                                                            height: 38px;
                                                        }

                                                        .dropText {
                                                            color: #FFFFFF;
                                                            font-size: 1rem;
                                                            font-weight: 600;
                                                            letter-spacing: 0;
                                                            line-height: 39px;
                                                            text-align: center;

                                                            @media screen and (max-width:1440px) {
                                                                font-size: 0.9rem;
                                                                line-height: 37px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .divider {
                                            float: left;
                                            width: 100%;
                                            margin-bottom: 20px;
                                            margin-top: 0px;

                                            span {
                                                color: #6C7789;
                                                font-size: 1.563rem;
                                                letter-spacing: 0;
                                                line-height: 32px;
                                                text-align: center;
                                                font-weight: 400;

                                                @media screen and (max-width:1440px) {
                                                    font-size: 1.50rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                span {
                    margin: 0 6px;
                    display: inline-block;
                    color: #6A7587;
                    font-size: 0.875rem;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 19px;
                }

                &:hover {
                    .user-image-timeline {
                        opacity: 1;
                        visibility: visible;
                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease; 
                        -ms-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    } 
    &.addtiveFull-Bar{
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: 0px;
        margin-bottom: 35px;
        .filterSection{
            @media screen and (max-width:480px){
                width:100%;
                display:block;
                margin-top:30px;
                position:relative;
                h2{
                    margin-right: 0;
                    margin-bottom: 15px;
                    line-height: 26px;
                }
                .main-search{
                    width:100%;
                    margin-bottom: 15px;
                }
            }
        }        
    } 
    &.addtiveColumn-Bar{
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        gap: 10px;
        margin-bottom: 30px;
        .filterBar-coloum{
            display: flex;
            flex-flow: wrap;
            .main-search-1{                
                position:relative;   
                width:450px;
                height:48px;   
                @media screen and (max-width:1440px) {
                    width:430px;
                }
                @media screen and (max-width:1366px) {
                    width:410px;
                }
                input[type="text"]{
                    width:450px;
                    height:44px;  
                    color: #6C7789;
                    font-family: 'PT Sans';
                    font-size: 1rem;
                    letter-spacing: 0;
                    line-height: 27px;       
                    font-weight: 400;
                    border-radius:8px;
                    @media screen and (max-width:1440px) {
                        width:430px;
                    }
                    @media screen and (max-width:1366px) {
                        width:410px;
                    }
                }
                .css-1s2u09g-control, .css-1pahdxg-control, .css-13cymwt-control{
                    min-height: 48px !important;
                    height: 48px !important; 
                    color: #6C7789!important;
                    background:#fff!important;
                    border: 2px solid #E9E9E9!important;
                    padding:0px 0px!important;
                }
                .css-6j8wv5-Input{
                    height: 44px !important; 
                }
                .css-14el2xx-placeholder {
                    margin: 0px !important;
                    height: 42px;
                    line-height: 43px;
                }
                .css-tj5bde-Svg{
                    display:none;
                }
                .css-1pndypt-Input{
                    margin:0px!important;
                    padding:0px!important;
                    height: 44px !important; 
                    color: #6C7789!important;
                }
                .css-319lph-ValueContainer{
                    height: 44px !important; 
                    font-size: 1rem !important;
                    font-weight: 400;
                }
                button{
                    position: absolute;
                    top:8px;
                    right:8px;
                    height: 32px;
                    width: 32px;
                    border-radius: 4px;
                    background-color: #7FB541;
                    border: none;
                    line-height: 13px;
                    svg{
                        margin-top:0px;  
                    }
                    &.closeBtn{
                        right:46px;
                        background: none;
                        width: 26px;
                        top:8px;
                        border-radius: 0px;       
                    }
                } 
                .filterDropdown{
                    position: absolute;
                    width: 100%;
                    padding: 10px 15px;
                    background: #fff;
                    border: 2px solid #E9E9E9;
                    border-radius: 0 0 8px 8px;
                    -webkit-border-radius: 0 0 8px 8px;
                    -moz-border-radius: 0 0 8px 8px;
                    border-top: none;
                    z-index: 9999;
                    top: 42px;   
                    li{
                        display:block;
                        a{
                            color: #6C7789;                          
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 37px;
                            display:block;
                            &:hover{
                                color:#7FB541;
                            }
                        }
                    }
                }    
            }
            .filterBtn {
                position: relative;
                margin-left: 16px;
                @media screen and (max-width:480px) {
                    margin-left:0px;
                }
                button {
                    background: #fff;
                    line-height: 13px;
                    height: 48px;
                    width: auto;
                    border: 2px solid #E9E9E9;
                    border-radius: 8px;
                    position: relative;
                    padding: 0 25px;
                    @media screen and (max-width:1440px) {
                        height: 44px;
                    }

                    label {
                        color: #6C7789;
                        font-size: 1rem;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 27px;
                        cursor: pointer;

                        @media screen and (max-width:1440px) {
                            font-size: 0.938rem;
                        }
                    }

                    span {
                        display: inline-block;
                        margin-right: 5px;

                        img {
                            &.imgFirst {
                                display: inline-block;
                                opacity: 1;
                            }

                            &.imghover {
                                display: none;
                                opacity: 0;
                            }
                        }
                    }

                    .clearFilter {
                        position: absolute;
                        top: -10px;
                        right: -10px;
                        z-index:0;
                    }

                    &:hover {
                        border-color: #7FB541;

                        label {
                            color: #7FB541;
                        }

                        span {
                            img {
                                &.imgFirst {
                                    display: none;
                                    opacity: 0;
                                }

                                &.imghover {
                                    display: inline-block;
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    &.active {
                        border-color: #7FB541;
                        background-color: #F9F9F9;

                        label {
                            color: #7FB541;
                        }

                        span {
                            img {
                                &.imgFirst {
                                    display: none;
                                    opacity: 0;
                                }

                                &.imghover {
                                    display: inline-block;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }    
    }
}
.additive-filter-listing{
    float: left;
    width: 100%;
    margin-bottom:30px;
    .filterType {
        padding: 0px;
        width: 100%;
        margin: 0px;
        float: left;    
        li {
            float: left;
            margin-right: 1%;
            width: 15.83%;
            position: relative;
            margin-bottom:15px;
            @media screen and (max-width:1680px){
                width:20%;
            }
            @media screen and (max-width:1440px){
                width:22%;
            }
            @media screen and (max-width:1280px){
                width:24%;
            }
            @media screen and (max-width:1023px){
                margin-right:3%;
                width:48.5%;  
                &:nth-child(2n){
                    margin-right:0px;                    
                }                               
            }
            @media screen and (max-width:480px){
                margin-right:0%;
                width:100%;  
                &:nth-child(2n){
                    margin-right:0px;                    
                }                               
            }
            .reworkBtn {
                height: 48px;
                width: 164px;
                border: 2px solid #E9E9E9;
                border-radius: 8px;
                background-color: #F9F9F9;
                color: #262726;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 27px;
                border-radius: 8px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                text-align: center;

                &:hover {
                    background-color: #7FB541;
                    color: #fff;
                    border-color: #7FB541;
                }
            }
            &.bigCol {
                width: 25%;
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 65%;
                    width: 5px;
                    background: #707070;
                    height: 2px;
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                }
            }
            &:nth-child(6){
                margin-right: 0px;                
            }
            &:last-child{
                margin-right: 0px;
            }

            label {
                opacity: 0.5;
                color: #6C7789;
                font-size: 1rem;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 30px;
                float: left;
                width: 100%;

                @media screen and (max-width:1600px) {
                    font-size: 0.85rem;
                }

                @media screen and (max-width:1440px) {
                    font-size: 0.875rem;
                    line-height: 27px;
                }
            }

            .css-b62m3t-container {
                width: 100%;
            }

            .css-1s2u09g-control,
            .css-1pahdxg-control {
                font-size: 1rem !important;
                font-weight: 400 !important;
                border: 2px solid #E9E9E9 !important;
                height: 48px !important;
                color: #707070 !important;
                border-radius: 8px !important;
                -webkit-border-radius: 8px !important;
                -moz-border-radius: 8px !important;
                width: 100% !important;
                min-height: 48px !important;
                box-shadow: none !important;
                -webkit-box-shadow: none !important;
                -moz-box-shadow: none !important;
                padding: 0px !important;
                box-sizing: border-box !important;
                background-color: #ffffff !important;
                outline: none !important;
                max-width: inherit !important;
                @media screen and (max-width:1600px) {
                    font-size: 0.85rem !important;
                }
                @media screen and (max-width:1440px) {
                    height: 44px !important;
                    min-height: 44px !important;
                    font-size: 0.85rem !important;
                }
                @media screen and (max-width:1366px) {
                    font-size: 0.8rem !important;
                }
                @media screen and (max-width:1280px) {
                    font-size: 0.7.5rem !important;
                }                   
            }

            .css-1wy0on6 {
                margin-right: 3px;
            }

            .css-1hb7zxy-IndicatorsContainer {
                margin-right: 3px;
            }

            .css-tlfecz-indicatorContainer {
                background: transparent;
                color: #707070;
                border-radius: 50%;
                width: 20px;
                height: 24px;
                margin: 0 2px;
                cursor: pointer;
                position: relative;
                padding: 1px;
            }

            .css-1gtu0rj-indicatorContainer {
                background: transparent;
                color: #707070;
                border-radius: 50%;
                width: 20px;
                height: 24px;
                margin: 0 2px;
                position: relative;
                padding: 1px;
            }

            .css-tj5bde-Svg {
                display: inline-block;
                fill: currentColor;
                line-height: 1;
                stroke: currentColor;
                stroke-width: 0;
                width: 18px;
                height: 18px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            .css-319lph-ValueContainer {
                height: 42px !important;
                padding: 0px 10px 0px 15px !important;

                @media screen and (max-width:1440px) {
                    height: 38px !important;
                    padding: 0px 10px !important;
                }
            }

            .css-1okebmr-indicatorSeparator {
                margin-left: 0px !important;
                width: 1px !important;
            }

            .css-1f43avz-a11yText-A11yText {
                margin-left: 0px !important;
                width: 1px !important;
            }

            .css-14el2xx-placeholder {
                margin: 0px !important;
            }

            .css-6j8wv5-Input {
                margin: 0 !important;
                padding-bottom: 0 !important;
                padding-top: 0 !important;
                color: #73788E !important;
            }

            .css-1okebmr-indicatorSeparator {
                display: none !important;
            }

            .css-6j8wv5-Input input {
                color: #73788E !important;
            }

            .css-qc6sy-singleValue {
                color: #73788E !important;
                margin: 0px !important;
            }

            .css-26l3qy-menu {
                margin-top: 0px !important;
                border: 1px solid #E9E9E9 !important;
                border-radius: 0 0 4px 4px !important;
                box-shadow: none !important;
                border-top: none !important;
            }

            .css-1n7v3ny-option {
                color: #6EB724 !important;
                font-size: 16px !important;
                letter-spacing: 0 !important;
                line-height: 20px !important;
                display: block !important;
                background-color: #fff !important;
                cursor: pointer !important;

                @media screen and (max-width:1440px) {
                    font-size: 13px !important;
                    line-height: 16px !important;
                    padding: 6px 10px !important;
                }
            }

            .css-yt9ioa-option {
                color: #6C7789 !important;
                cursor: pointer !important;
                font-size: 16px !important;
                letter-spacing: 0 !important;
                line-height: 20px !important;
                display: block !important;
                background-color: #fff !important;

                @media screen and (max-width:1440px) {
                    font-size: 13px !important;
                    line-height: 16px !important;
                    padding: 6px 10px !important;
                }
            }

            .css-4ljt47-MenuList {
                max-height: 300px !important;
                overflow-y: auto !important;
                padding-bottom: 0 !important;
                padding-top: 0 !important;
            }

            .css-9gakcf-option {
                background: #7FB541 !important;
                color: #fff !important;
            }

            input[type="text"] {
                font-size: 1rem;
                font-weight: 400;
                height: 48px;
                color: #707070;
                border-radius: 8px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;

                @media screen and (max-width:1600px) {
                    font-size: 0.85rem !important;
                }

                @media screen and (max-width:1440px) {
                    font-size: 0.85rem !important;
                    height: 44px;
                    padding: 5px 10px;
                }

                @media screen and (max-width:1366px) {
                    font-size: 0.8rem !important;
                }

                @media screen and (max-width:1280px) {
                    font-size: 0.7.5rem !important;
                }

                &:last-child {
                    margin-right: 0px;
                }
            }

            select {
                width: 100%;
                font-size: 1rem;
                font-weight: 400;
                color: #707070;
                height: 48px;
                border-radius: 8px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
            }

            .filterDropdown {
                top: 72px;

                li {
                    margin-right: 0px;
                    width: 100%;
                }
            }
        }        
    }
    &.MB0{
        margin-bottom:0px;
    }
    .range-filter {
        padding: 0px;
        width: 100%;
        margin: 0px;
        float: left;    
        li {
            float: left;
            width: 100%;
            width: 19.2%;
            margin-right:1%;
            padding:13px 17px 13px; 
            margin-bottom:30px;
            border:2px solid #E9E9E9;            
            position: relative;
            -moz-border-radius: 8px;
            -webkit-border-radius: 8px;
            border-radius: 8px;            
            &:nth-child(5n){
                margin-right: 0px;
            }
            .removeFilter{
                position: absolute;
                top:7px;
                right: 8px;
                z-index: 9999;
                line-height: 7px;
                display:block;
                img{
                    width:8px;
                }
            }    
            h3{
                position: absolute;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: #6C7789;
                background: #fff;
                padding: 0px 8px;
                top: -11px;
                left: 9px;                
            }
            .range-Value{
                width: 100%;
                margin:0px 0 0;
                float: left;
                .halfSec{
                    width:48%;
                    margin-right:4%;
                    float:left;
                    &:last-child{
                        margin-right:0px;
                    }
                    input[type="text"] {
                        font-size: 14px;
                        font-weight: 600;
                        height: 36px;
                        color: #464646;
                        border-radius: 6px;
                        -webkit-border-radius: 6px;
                        -moz-border-radius: 6px;
                        border: none;
                        background: #f8f8f8;  
                        text-align: center;                      
                    }
                    label {
                        color: #6C7789;
                        font-size: 13px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 22px;
                        float: left;
                        width: 100%;
                        text-align: left;
                    }
                }
            }           
                    
            .css-b62m3t-container {
                width: 100%;
            }
            .css-1s2u09g-control,
            .css-1pahdxg-control {
                font-size: 1rem !important;
                font-weight: 400 !important;
                border: 2px solid #E9E9E9 !important;
                height: 48px !important;
                color: #707070 !important;
                border-radius: 8px !important;
                -webkit-border-radius: 8px !important;
                -moz-border-radius: 8px !important;
                width: 100% !important;
                min-height: 48px !important;
                box-shadow: none !important;
                -webkit-box-shadow: none !important;
                -moz-box-shadow: none !important;
                padding: 0px !important;
                box-sizing: border-box !important;
                background-color: #ffffff !important;
                outline: none !important;
                max-width: inherit !important;
                @media screen and (max-width:1600px) {
                    font-size: 0.85rem !important;
                }
                @media screen and (max-width:1440px) {
                    height: 44px !important;
                    min-height: 44px !important;
                    font-size: 0.85rem !important;
                }
                @media screen and (max-width:1366px) {
                    font-size: 0.8rem !important;
                }
                @media screen and (max-width:1280px) {
                    font-size: 0.7.5rem !important;
                }                   
            }

            .css-1wy0on6 {
                margin-right: 3px;
            }

            .css-1hb7zxy-IndicatorsContainer {
                margin-right: 3px;
            }

            .css-tlfecz-indicatorContainer {
                background: transparent;
                color: #707070;
                border-radius: 50%;
                width: 20px;
                height: 24px;
                margin: 0 2px;
                cursor: pointer;
                position: relative;
                padding: 1px;
            }

            .css-1gtu0rj-indicatorContainer {
                background: transparent;
                color: #707070;
                border-radius: 50%;
                width: 20px;
                height: 24px;
                margin: 0 2px;
                position: relative;
                padding: 1px;
            }

            .css-tj5bde-Svg {
                display: inline-block;
                fill: currentColor;
                line-height: 1;
                stroke: currentColor;
                stroke-width: 0;
                width: 18px;
                height: 18px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            .css-319lph-ValueContainer {
                height: 42px !important;
                padding: 0px 10px 0px 15px !important;

                @media screen and (max-width:1440px) {
                    height: 38px !important;
                    padding: 0px 10px !important;
                }
            }

            .css-1okebmr-indicatorSeparator {
                margin-left: 0px !important;
                width: 1px !important;
            }

            .css-1f43avz-a11yText-A11yText {
                margin-left: 0px !important;
                width: 1px !important;
            }

            .css-14el2xx-placeholder {
                margin: 0px !important;
            }

            .css-6j8wv5-Input {
                margin: 0 !important;
                padding-bottom: 0 !important;
                padding-top: 0 !important;
                color: #73788E !important;
            }

            .css-1okebmr-indicatorSeparator {
                display: none !important;
            }

            .css-6j8wv5-Input input {
                color: #73788E !important;
            }

            .css-qc6sy-singleValue {
                color: #73788E !important;
                margin: 0px !important;
            }

            .css-26l3qy-menu {
                margin-top: 0px !important;
                border: 1px solid #E9E9E9 !important;
                border-radius: 0 0 4px 4px !important;
                box-shadow: none !important;
                border-top: none !important;
            }

            .css-1n7v3ny-option {
                color: #6EB724 !important;
                font-size: 16px !important;
                letter-spacing: 0 !important;
                line-height: 20px !important;
                display: block !important;
                background-color: #fff !important;
                cursor: pointer !important;

                @media screen and (max-width:1440px) {
                    font-size: 13px !important;
                    line-height: 16px !important;
                    padding: 6px 10px !important;
                }
            }

            .css-yt9ioa-option {
                color: #6C7789 !important;
                cursor: pointer !important;
                font-size: 16px !important;
                letter-spacing: 0 !important;
                line-height: 20px !important;
                display: block !important;
                background-color: #fff !important;

                @media screen and (max-width:1440px) {
                    font-size: 13px !important;
                    line-height: 16px !important;
                    padding: 6px 10px !important;
                }
            }

            .css-4ljt47-MenuList {
                max-height: 300px !important;
                overflow-y: auto !important;
                padding-bottom: 0 !important;
                padding-top: 0 !important;
            }

            .css-9gakcf-option {
                background: #7FB541 !important;
                color: #fff !important;
            }

            

            select {
                width: 100%;
                font-size: 1rem;
                font-weight: 400;
                color: #707070;
                height: 48px;
                border-radius: 8px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
            }

            .filterDropdown {
                top: 72px;

                li {
                    margin-right: 0px;
                    width: 100%;
                }
            }
        }        
    }
}
.addPlant-block{    
    float:left;
    width:100%;        
    margin-top: 25px;
    .plant-formSection{
        display:flex;
        .formFields{
            flex: 0 46.5%;
            li{
                float:left;
                width:100%;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                position: relative;
                align-items: center;
                margin-bottom: 25px;
                label{
                    width:110px;
                    position:absolute;
                    left:0px;      
                    color: #73788E;
                    font-family: 'PT Sans';
                    font-size: 0.75rem;
                    letter-spacing: 0;
                    line-height: 20px;
                    text-align: right;     
                    &.lineHeight{
                        line-height: 15px;
                    }         
                }
                span{
                    margin-left:130px;
                    width: 100%;                        
                    input[type="text"], 
                    input[type="email"],  
                    input[type="password"]{
                        width: 100%;
                        max-width: 321px;
                        height:38px;
                        box-shadow: none;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        font-size: 0.75rem;
                        font-weight: bold;
                        letter-spacing: 0;
                        color:#73788E;
                        line-height: 20px;
                        border-radius:4px;
                        -webkit-border-radius:4px;
                        -moz-border-radius:4px;
                        border:none;  
                        display: block;                                            
                        padding:5px 15px;                        
                        box-sizing: border-box;
                        background-color: #F5F5FA;
                        outline:none;
                        &:focus{                            
                            color:#73788E; 
                        }
                        &:focus::placeholder{                            
                            color:#73788E; 
                        }
                        &:focus:-ms-input-placeholder{                            
                            color:#73788E; 
                        }
                        &:focus::-ms-input-placeholder{                            
                            color:#73788E; 
                        } 
                    } 
                    textarea{
                        box-shadow: none;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        color: #73788E;    
                        font-size: 0.75rem;
                        max-width:555px;
                        font-family: 'PT Sans';
                        font-weight:700; 
                        border-radius:4px;
                        -webkit-border-radius:4px;
                        -moz-border-radius:4px;
                        border:none;  
                        display: block;
                        width: 100%;
                        height:175px;
                        padding:10px 15px;
                        background: #F5F5FA;
                        box-sizing: border-box;
                        outline:none;  
                        resize: none;
                    }
                    select{
                        box-shadow: none;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        color: #73788E;    
                        font-size: 0.75rem;
                        font-family: 'PT Sans';
                        font-weight:700; 
                        border-radius:4px;
                        -webkit-border-radius:4px;
                        -moz-border-radius:4px;
                        border:none;  
                        display: block;
                        width: 100%;
                        max-width: 321px;
                        height:38px;
                        padding:5px 35px 5px 15px ;
                        background:#F5F5FA url(../assets/images/down-arrow.png) no-repeat 95% 16px;
                        background-size: 10px;
                        box-sizing: border-box;
                        outline:none;
                        appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        &:focus{                            
                            color:#73788E; 
                        }
                        &:focus::placeholder{                                
                            color:#73788E; 
                        }
                        &:focus:-ms-input-placeholder{                                
                            color:#73788E; 
                        }
                        &:focus::-ms-input-placeholder{                                
                            color:#73788E; 
                        }
                    } 
                    .filterDropdown{
                        top:37px;
                        width:321px;   
                        border: 1px solid #E9E9E9;
                        li{
                            margin-right:0px;
                            width:100%;  
                            margin-bottom: 0;       
                        }
                    }                      
                }
                &.topAlign{
                    align-items: start;
                }
            }
        }
    }
    .action-btn{
        margin-right: 297px;
        margin-top: 15px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        margin-bottom: 80px;
        justify-content: end;
        li{
            float:left;
            margin-right:5px;
            &:last-child{
                margin-right:0px;
            }
            a{
                color: #7FB541;
                font-size: 1rem;
                letter-spacing: 0;
                line-height: 29px;
                text-align: center;
                display: inline-block;
                padding: 5px 20px;
                height: 40px;
                &:hover, &.active{
                    border-radius: 4px;
                    background-color: #7FB541;
                    color:#fff;
                }
            }
        }
    }
}
.additiveTable-New{
    float:left;
    width:100%;
     h3{
        color: #262726;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        display:block;
        margin-bottom:15px;
        line-height: 26px;
    }      
}

.action-btn-n {
    padding: 10px 20px;           /* Adjust padding for button size */
    font-size: 16px;              /* Text size */
    color: black;                 /* Text color */
    background-color: white;      /* Button background */
    border: 1px solid black;      /* Solid black border */
    border-radius: 5px;           /* Rounded corners */
    cursor: pointer;              /* Changes cursor to pointer on hover */
    transition: background-color 0.3s ease; /* Smooth hover transition */
}