.report-container{
    float:left;
    width:100%;   
    .report-filter-list{
        float: left;
        width: 100%;
        margin-bottom:30px;
        position:relative; 
        &.MB10{
            margin-bottom:10px;
        }   
        .filterType {
            padding: 0px;
            width: 100%;
            margin: 0px;
            float: left;    
            li {
                float: left;
                margin-right: 1%;
                width: 31.83%;
                position: relative;
                margin-bottom:15px;
                @media screen and (max-width:1023px){
                    margin-right:3%;
                    width:48.5%;  
                    &:nth-child(2n){
                        margin-right:0px;                    
                    }                               
                }
                @media screen and (max-width:480px){
                    margin-right:0%;
                    width:100%;  
                    &:nth-child(2n){
                        margin-right:0px;                    
                    }                               
                }
                &.wdSmall{
                    width:270px;
                }
                &.wdRight{
                    width:auto;
                    float:right;
                }
                &.wdLarge{
                    width: 67.17%;
                    float: left;
                }
                &.bigCol {
                    width: 25%;
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 65%;
                        width: 5px;
                        background: #707070;
                        height: 2px;
                        transform: translate(-50%, -50%);
                        -webkit-transform: translate(-50%, -50%);
                        -moz-transform: translate(-50%, -50%);
                    }
                }
                &:nth-child(6){
                    margin-right: 0px;                
                }
                &:last-child{
                    margin-right: 0px;
                }

                label {
                    opacity: 0.5;
                    color: #6C7789;
                    font-size: 1rem;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 30px;
                    float: left;
                    width: 100%;

                    @media screen and (max-width:1600px) {
                        font-size: 0.85rem;
                    }

                    @media screen and (max-width:1440px) {
                        font-size: 0.875rem;
                        line-height: 27px;
                    }
                }
                .myAssign{
                    position: absolute;
                    z-index:999;
                    left:0px;
                    bottom: -22px;                
                    color: #6C7789;
                    font-weight: 600;                
                    cursor: pointer;
                    &:hover{
                        color: #7FB541;
                    }
                    &.active{
                        color: #7FB541;                    
                    }
                }
                .myAssign-right{
                    position: absolute;
                    z-index:999;
                    right:0px;
                    top:0px;                
                    color: #6C7789;
                    font-weight: 600;                
                    cursor: pointer;
                    &:hover{
                        color: #7FB541;                    
                    }
                    &.active{
                        color: #7FB541;                    
                    }
                }
                /*.css-b62m3t-container {
                    width: 100%;
                    float: left;
                }

                .css-1s2u09g-control,
                .css-1pahdxg-control,
                .css-13cymwt-control {
                    font-size: 1rem !important;
                    font-weight: 400 !important;
                    border: 2px solid #E9E9E9 !important;
                    height: 48px !important;
                    color: #707070 !important;
                    border-radius: 8px !important;
                    -webkit-border-radius: 8px !important;
                    -moz-border-radius: 8px !important;
                    width: 100% !important;
                    min-height: 48px !important;
                    box-shadow: none !important;
                    -webkit-box-shadow: none !important;
                    -moz-box-shadow: none !important;
                    padding: 0px !important;
                    box-sizing: border-box !important;
                    background-color: #ffffff !important;
                    outline: none !important;
                    max-width: inherit !important;
                    @media screen and (max-width:1600px) {
                        font-size: 0.85rem !important;
                    }
                    @media screen and (max-width:1440px) {
                        height: 44px !important;
                        min-height: 44px !important;
                        font-size: 0.85rem !important;
                    }
                    @media screen and (max-width:1366px) {
                        font-size: 0.8rem !important;
                    }
                    @media screen and (max-width:1280px) {
                        font-size: 0.7.5rem !important;
                    }                   
                }

                .css-1wy0on6 {
                    margin-right: 3px;
                }

                .css-1hb7zxy-IndicatorsContainer {
                    margin-right: 3px;
                }

                .css-tlfecz-indicatorContainer {
                    background: transparent;
                    color: #707070;
                    border-radius: 50%;
                    width: 20px;
                    height: 24px;
                    margin: 0 2px;
                    cursor: pointer;
                    position: relative;
                    padding: 1px;
                }

                .css-1gtu0rj-indicatorContainer {
                    background: transparent;
                    color: #707070;
                    border-radius: 50%;
                    width: 20px;
                    height: 24px;
                    margin: 0 2px;
                    position: relative;
                    padding: 1px;
                }                

                .css-319lph-ValueContainer {
                    height: 42px !important;
                    padding: 0px 10px 0px 15px !important;

                    @media screen and (max-width:1440px) {
                        height: 38px !important;
                        padding: 0px 10px !important;
                    }
                }

                .css-1okebmr-indicatorSeparator {
                    margin-left: 0px !important;
                    width: 1px !important;
                }

                .css-1f43avz-a11yText-A11yText {
                    margin-left: 0px !important;
                    width: 1px !important;
                }

                .css-14el2xx-placeholder {
                    margin: 0px !important;
                }

                .css-6j8wv5-Input {
                    margin: 0 !important;
                    padding-bottom: 0 !important;
                    padding-top: 0 !important;
                    color: #73788E !important;
                }

                .css-1okebmr-indicatorSeparator {
                    display: none !important;
                }

                .css-6j8wv5-Input input {
                    color: #73788E !important;
                }

                .css-qc6sy-singleValue {
                    color: #73788E !important;
                    margin: 0px !important;
                }

                .css-26l3qy-menu {
                    margin-top: 0px !important;
                    border: 1px solid #E9E9E9 !important;
                    border-radius: 0 0 4px 4px !important;
                    box-shadow: none !important;
                    border-top: none !important;
                }

                .css-1n7v3ny-option {
                    color: #6EB724 !important;
                    font-size: 16px !important;
                    letter-spacing: 0 !important;
                    line-height: 20px !important;
                    display: block !important;
                    background-color: #fff !important;
                    cursor: pointer !important;

                    @media screen and (max-width:1440px) {
                        font-size: 13px !important;
                        line-height: 16px !important;
                        padding: 6px 10px !important;
                    }
                }

                .css-yt9ioa-option {
                    color: #6C7789 !important;
                    cursor: pointer !important;
                    font-size: 16px !important;
                    letter-spacing: 0 !important;
                    line-height: 20px !important;
                    display: block !important;
                    background-color: #fff !important;

                    @media screen and (max-width:1440px) {
                        font-size: 13px !important;
                        line-height: 16px !important;
                        padding: 6px 10px !important;
                    }
                }

                .css-4ljt47-MenuList {
                    max-height: 300px !important;
                    overflow-y: auto !important;
                    padding-bottom: 0 !important;
                    padding-top: 0 !important;
                }

                .css-9gakcf-option {
                    background: #7FB541 !important;
                    color: #fff !important;
                }*/

                input[type="text"] {                    
                    height: 46px;
                    color: #707070;
                    border-radius: 8px;
                    -webkit-border-radius: 8px;
                    -moz-border-radius: 8px;
                    
                    @media screen and (max-width:1440px) {                        
                        padding: 5px 10px;
                    }                    
                    &:last-child {
                        margin-right: 0px;
                    }
                }

                select {
                    width: 100%;
                    font-size: 1rem;
                    font-weight: 400;
                    color: #707070;
                    height: 46px;  
                    border-radius: 8px;
                    -webkit-border-radius: 8px;
                    -moz-border-radius: 8px;
                }

                .filterDropdown {
                    top: 72px;

                    li {
                        margin-right: 0px;
                        width: 100%;
                    }
                }
                .btn-run{
                    color: #fff;
                    font-size: 1rem;
                    letter-spacing: 0;
                    line-height: 29px;
                    text-align: center;
                    display: inline-block;
                    padding: 5px 20px;                    
                    border-radius: 4px;
                    width: 150px;
                    background: #7FB541;
                    height: 46px;
                    border: none;
                    margin-top: 29px;
                    border-radius: 8px;
                    -webkit-border-radius: 8px;
                    -moz-border-radius: 8px;
                    @media screen and (max-width:1440px) {
                        margin-top: 27px;
                        height:44px;  
                    }
                }
            }

            &.additives-filter {
                margin-top: 32px;

                li {
                    .reworkBtn {
                        height: 48px;
                        width: 164px;
                        border: 2px solid #E9E9E9;
                        border-radius: 8px;
                        background-color: #F9F9F9;
                        color: #262726;
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 27px;
                        border-radius: 8px;
                        -webkit-border-radius: 8px;
                        -moz-border-radius: 8px;
                        text-align: center;

                        &:hover {
                            background-color: #7FB541;
                            color: #fff;
                            border-color: #7FB541;
                        }
                    }
                }
            }
        }
        .results-right-side {
            padding: 0px;
            position: absolute;
            right: 20px;
            top: 20px;            
            .rightOption {
                float: left;
                width: 100%;
                text-align: right;
                margin: 0;
                align-items: center;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                gap: 20px;
                li {
                    float: left;
                    margin-right: 5px;
                    position: relative;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -moz-flex;
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;

                    &:last-child {
                        margin-right: 0px;
                    }

                    a {
                        color: #6A7587;
                        font-size:18px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 19px;
                        display:flex; 
                        align-items: center;
                        span {
                            margin: 0 6px;
                            display: inline-block;
                            color: #6A7587;
                            font-size: 0.875rem;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 19px;
                            img {
                                &.imgFirst {
                                    display: inline-block;
                                    opacity: 1;
                                }

                                &.imghover {
                                    display: none;
                                    opacity: 0;
                                }
                            }
                        }
                        &.downloadIcon{
                            span{
                                width:30px;   
                            }
                        }
                        &:hover,
                        &.active {
                            span {
                                img {
                                    &.imgFirst {
                                        display: none;
                                        opacity: 0;
                                    }

                                    &.imghover {
                                        display: inline-block;
                                        opacity: 1;
                                    }
                                }
                            }
                            color:#7FB541;   
                        }
                    }                   
                    &:hover {
                        .user-image-timeline {
                            opacity: 1;
                            visibility: visible;
                            -webkit-transition: all 0.3s ease;
                            -moz-transition: all 0.3s ease;
                            -ms-transition: all 0.3s ease;
                            -o-transition: all 0.3s ease;
                            transition: all 0.3s ease;
                        }
                    }
                }
            }
        }
    }
}    