.profile-container {
    float: left;
    width: 100%;
    margin-top: 0px;
    background: #F5F7FB;
    border-radius: 16px;
    padding: 60px 50px 60px;

    @media screen and (max-width:1440px) {
        padding: 50px;
    }

    .profileCol-left {
        position: relative;
        width: 100%;
        display: table;

        .profileFullSection {
            position: absolute;
            left: 50px;

            @media screen and (max-width:1440px) {
                left: 30px;
            }

            .profile-image {
                position: relative;
                background-color: #FFFFFF;
                width: 146px;
                height: 146px;
                border-radius: 100%;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                display: block;

                @media screen and (max-width:1440px) {
                    width: 125px;
                    height: 125px;
                }

                @media screen and (max-width:1366px) {
                    width: 115px;
                    height: 115px;
                }

                &:after {
                    position: absolute;
                    width: 50px;
                    height: 100px;
                    content: "";
                    z-index: 999;
                    top: -10px;
                    right: -39px;
                }

                span {
                    border-radius: 100%;
                    overflow: hidden;
                    left: 125px;
                    width: 146px;
                    height: 146px;
                    cursor: pointer;
                    display: block;

                    @media screen and (max-width:1440px) {
                        width: 125px;
                        height: 125px;
                    }

                    @media screen and (max-width:1366px) {
                        width: 115px;
                        height: 115px;
                    }

                    img {
                        width: 100%;
                        object-fit: contain;
                        height: 100%;
                    }
                }

                .editSec {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 9999;
                    background: rgba(0, 0, 0, 0.5);
                    border-radius: 100%;
                    width: 36px;
                    height: 36px;
                    z-index: 999999;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -moz-flex;
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media screen and (max-width:1440px) {
                        width: 32px;
                        height: 32px;
                    }

                    img {
                        width: 20px;

                        @media screen and (max-width:1440px) {
                            width: 16px;
                        }
                    }
                }

                .avatarBlock {
                    position: absolute;
                    top: -15px;
                    background: #fff;
                    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
                    padding: 15px;
                    border-radius: 15px;
                    left: 0px;
                    z-index: 999;
                    width: 1145px;
                    border: 1px solid #ccc;
                    transform: translate(0px, -100%);
                    -webkit-transform: translate(0px, -100%);
                    -moz-transform: translate(0px, -100%);

                    @media screen and (max-width:1600px) {
                        width: 1079px;
                    }

                    @media screen and (max-width:1440px) {
                        width: 983px;
                    }

                    @media screen and (max-width:1366px) {
                        width: 918px;
                    }

                    &:after,
                    &:before {
                        top: 100%;
                        left: 75px;
                        border: solid transparent;
                        content: "";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }

                    &:after {
                        border-color: rgba(255, 255, 255, 0);
                        border-top-color: #fff;
                        border-width: 15px;
                        margin-left: -15px;
                    }

                    &:before {
                        border-color: rgba(204, 204, 204, 0);
                        border-top-color: #ccc;
                        border-width: 16px;
                        margin-left: -16px;
                    }

                    span {
                        position: absolute;
                        right: -7px;
                        top: -10px;
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                        left: auto;
                        overflow: visible;
                        border-radius: inherit;

                        img {
                            width: 100%;
                        }
                    }

                    .avatar-profile {
                        float: left;
                        width: 100%;

                        li {
                            float: left;
                            width: 60px;
                            height: 60px;
                            margin-right: 10px;
                            overflow: hidden;
                            border-radius: 100%;
                            background: #efefef;
                            border-radius: 100%;
                            -webkit-border-radius: 100%;
                            -moz-border-radius: 100%;
                            cursor: pointer;

                            @media screen and (max-width:1600px) {
                                width: 56px;
                                height: 56px;
                            }

                            @media screen and (max-width:1440px) {
                                width: 50px;
                                height: 50px;
                            }

                            @media screen and (max-width:1366px) {
                                width: 46px;
                                height: 46px;
                            }

                            &:last-child {
                                margin-right: 0px;
                            }

                            img {
                                width: 100%;
                            }
                        }
                    }
                }

                /*&:hover{
                    .avatar-profile{
                        opacity:1;
                        visibility:visible;   
                        -webkit-transition: .4s;
                        transition: .4s;
                        -webkit-transform: translateX(5px);
                        -ms-transform: translateX(5px);
                        transform: translateX(5px);
                        @media screen and (max-width:1600px){ 
                            -webkit-transform: translateX(-30px);
                            -ms-transform: translateX(-30px);
                             transform: translateX(-30px);
                        }
                        @media screen and (max-width:1440px){  
                            -webkit-transform: translateX(-50px);
                            -ms-transform: translateX(-50px);
                             transform: translateX(-50px);
                        }
                        @media screen and (max-width:1366px){ 
                            -webkit-transform: translateX(-70px);
                            -ms-transform: translateX(-70px);
                             transform: translateX(-70px);
                        }
                    }
                }*/
            }

            .userStatus {
                float: left;
                width: 100%;
                margin-top: 10px;

                .statusDropdown {
                    float: left;
                    width: 100%;

                    li {
                        padding: 0px;
                        position: relative;
                        text-align: center;

                        span {
                            font-size: 16px;
                            font-weight: 600;
                            padding: 0px 20px 5px 25px;
                            line-height: 17px;
                            color: #73788E;
                            position: relative;
                            cursor: pointer;

                            &.active {
                                color: #00C84F;

                                &:after {
                                    background: #00C84F;
                                    position: absolute;
                                    left: 6px;
                                    top: 5px;
                                    width: 12px;
                                    height: 12px;
                                    border-radius: 100%;
                                    content: "";
                                }
                            }

                            &.inactive {
                                color: #FF6D3A;

                                &:after {
                                    background: #FF6D3A;
                                    position: absolute;
                                    left: 6px;
                                    top: 5px;
                                    width: 12px;
                                    height: 12px;
                                    border-radius: 100%;
                                    content: "";
                                }
                            }

                            &.deleted {
                                color: #EB557B;

                                &:after {
                                    background: #EB557B;
                                    position: absolute;
                                    left: 6px;
                                    top: 5px;
                                    width: 12px;
                                    height: 12px;
                                    border-radius: 100%;
                                    content: "";
                                }
                            }
                        }

                        ol {
                            float: left;
                            display: block;
                            width: 100%;
                            background: #fff;
                            position: absolute;
                            top: 23px;
                            border-top: none;
                            display: none;

                            li {
                                border-radius: 0px;
                                border: none;
                                background: none;

                                a {
                                    font-size: 16px;
                                    display: block;
                                    font-weight: 600;
                                    padding: 5px 25px;
                                    line-height: 24px;

                                    &:hover {
                                        color: #6EB724;
                                    }
                                }
                            }
                        }

                        &:hover {
                            ol {
                                display: block;

                                li {
                                    &:hover {
                                        background: #7FB541;

                                        a {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .css-1s2u09g-control,
                .css-1pahdxg-control {
                    background-color: #fff !important;
                    border: 1px solid #ccc !important;
                    border-radius: 50px !important;
                    padding: 0px 15px !important;
                }

                .css-1hb7zxy-IndicatorsContainer {
                    display: none;
                }
            }
        }
    }

    .profileCol-right {
        position: relative;
        width: 70%;
        display: table;

        h2 {
            color: #262726;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 26px;
            display: block;
            margin: 0 0 30px;
        }
    }

    .profileForm {
        float: left;
        width: 70%;
        padding-left: 290px;

        @media screen and (max-width:1440px) {
            width: 80%;
            padding-left: 230px;
        }

        @media screen and (max-width:1366px) {
            padding-left: 220px;
        }

        .profile-Listing {
            width: 100%;

            li {
                float: left;
                width: 100%;
                position: relative;
                margin-bottom: 25px;

                @media screen and (max-width:1366px) {
                    margin-bottom: 20px;
                }

                &:last-child {
                    margin-bottom: 0px;
                }

                &.colHalf {
                    width: 48%;
                    float: left;
                    margin-right: 4%;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }

                .halfCol {
                    width: 48%;
                    float: left;
                    margin-right: 4%;
                    position: relative;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }

                label {
                    color: #73788E;
                    font-family: 'PT Sans';
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 20px;
                    text-align: left;
                    width: 100%;
                    margin-bottom: 5px;
                    position: relative;

                    &.lineHeight {
                        line-height: 15px;
                    }
                }

                strong {
                    position: relative;

                    &.eyeIcon {
                        position: absolute;
                        top: 33px;
                        right: 15px;
                        width: auto;
                        margin: 0px;

                        .eye-icon {
                            cursor: pointer;

                            &:hover {
                                filter: grayscale(1) invert(1);
                            }
                        }
                    }
                }

                input[type="number"] {
                    -moz-appearance: textfield;
                }

                input[type="number"]::-webkit-outer-spin-button,
                input[type="number"]::-webkit-inner-spin-button {
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    margin: 0;
                }

                input[type="text"],
                input[type="email"],
                input[type="number"],
                input[type="password"] {
                    width: 100%;
                    height: 38px;
                    box-shadow: none;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0;
                    color: #73788E;
                    line-height: 20px;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    border: none;
                    display: block;
                    padding: 5px 15px;
                    box-sizing: border-box;
                    background-color: #fff;
                    outline: none;

                    &:focus {
                        color: #73788E;
                    }

                    &:focus::placeholder {
                        color: #73788E;
                    }

                    &:focus:-ms-input-placeholder {
                        color: #73788E;
                    }

                    &:focus::-ms-input-placeholder {
                        color: #73788E;
                    }
                }

                textarea {
                    box-shadow: none;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0;
                    color: #73788E;
                    font-family: 'PT Sans';
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    border: none;
                    display: block;
                    width: 100%;
                    height: 72px;
                    padding: 10px 15px;
                    background: #fff;
                    box-sizing: border-box;
                    outline: none;
                    resize: none;
                }

                select {
                    box-shadow: none;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    color: #73788E;
                    font-size: 12px;
                    font-family: 'PT Sans';
                    font-weight: 700;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    border: none;
                    display: block;
                    width: 100%;
                    height: 38px;
                    padding: 5px 35px 5px 15px;
                    background: #fff url(../assets/images/down-arrow.png) no-repeat 95% 16px;
                    background-size: 10px;
                    box-sizing: border-box;
                    outline: none;
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;

                    &:focus {
                        color: #73788E;
                    }

                    &:focus::placeholder {
                        color: #73788E;
                    }

                    &:focus:-ms-input-placeholder {
                        color: #73788E;
                    }

                    &:focus::-ms-input-placeholder {
                        color: #73788E;
                    }
                }

                &.topAlign {
                    align-items: start;
                }

                .css-1s2u09g-control,
                .css-1pahdxg-control {
                    background-color: #fff !important;
                }

                &.roles-error-msg {
                    .css-6j8wv5-Input {
                        height: 34px;
                    }

                    .css-1s2u09g-control,
                    .css-1pahdxg-control {
                        border: 2px solid #D41C27 !important;
                    }

                    input[type="text"] {
                        height: 36px;
                    }

                    .css-319lph-ValueContainer {
                        height: 34px !important;
                    }

                    .css-1gtu0rj-indicatorContainer {
                        padding: 5px 8px !important;
                    }
                }

                .css-g1d714-ValueContainer {
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                }

                .css-12jo7m5 {
                    font-size: 100% !important;
                    color: #fff !important;
                }

                .css-jzldcf-Input {
                    margin: 0 !important;
                    padding-bottom: 0 !important;
                    padding-top: 0 !important;
                }

                .css-tlfecz-indicatorContainer {
                    padding: 6px 8px !important;
                }

                .css-1rhbuit-multiValue {
                    background: #7FB541;
                }

                .css-xb97g8 {
                    .css-tj5bde-Svg {
                        color: #fff !important;
                    }
                }

                .css-xb97g8:hover {
                    background-color: red !important;
                    color: #fff !important;
                }
            }
        }

        &.passwordSection {
            padding-left: 0px;
            width: 100%;

            .profileCol-right {
                width: 50%;
                float: left;
                margin-right: 5%;

                @media screen and (max-width:1440px) {
                    width: 48%;
                    float: left;
                    margin-right: 3%;
                }

                .profile-Listing {
                    width: 80%;
                    display: table;

                    li {
                        .halfCol {
                            width: 100%;
                            margin: 0px;
                        }
                    }
                }
            }

            .passwordInfo-block {
                float: left;
                width: 45%;

                @media screen and (max-width:1440px) {
                    width: 49%;
                }

                .passwordInfo {
                    float: left;
                    max-width: 700px;
                    width: 100%;
                    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
                    padding: 45px;
                    background: #fff;
                    border-radius: 15px;

                    @media screen and (max-width:1440px) {
                        padding: 35px 45px;
                    }

                    h3 {
                        color: #262726;
                        font-size: 24px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 26px;
                        display: block;
                        margin: 0 0 20px;

                        @media screen and (max-width:1440px) {
                            font-size: 22px;
                            line-height: 24px;
                            margin: 0 0 15px;
                        }
                    }

                    .password-list {
                        float: left;
                        width: 100%;

                        li {
                            float: left;
                            width: 100%;
                            color: #262726;
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0;
                            line-height: 18px;
                            display: block;
                            margin: 0 0 12px;
                            position: relative;
                            padding-left: 30px;

                            @media screen and (max-width:1440px) {
                                font-size: 14px;
                            }

                            &:after {
                                content: "";
                                width: 20px;
                                margin-left: 0;
                                height: 20px;
                                background: url(../assets/images/leaf-shapes.svg) no-repeat 0px -1px;
                                position: absolute;
                                background-size: 100%;
                                left: 0px;
                                top: 2px;
                            }
                        }
                    }
                }
            }
        }

        .profile-action-btn {
            float: left;
            width: 100%;
            text-align: center;
            margin-top: 35px;

            @media screen and (max-width:1366px) {
                margin-top: 25px;
            }

            .action-btn:hover {
                background-color: #f0f0f0;
                /* Light grey background on hover */
            }

            /*&.MT69{
                margin-top:69px;
                @media screen and (max-width:1366px){ 
                    margin-top:59px;
                }
            }*/
            .btnSmall {
                color: #fff;
                font-size: 1rem;
                letter-spacing: 0;
                line-height: 25px;
                text-align: center;
                display: inline-block;
                padding: 5px 20px;
                height: 38px;
                background: #7FB541;
                border: none;
                border-radius: 5px;

                @media screen and (max-width:1366px) {
                    font-size: 0.9rem;
                }
            }
        }
    }
}