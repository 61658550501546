.pagination-section {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.pagination {
  padding: 0;
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
}

.pagination li {
  margin-right: 8px;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background-color: #F4F7F6;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove box shadow */
}

.pagination li a {
  color: #6A7587;
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none !important; /* Remove underline */
  border: none !important; /* Remove border */
  outline: none !important; /* Remove outline */
  box-shadow: none !important; /* Remove box shadow */
}

.pagination li.active {
  background: #6A7587;
  color: #fff;
  border: none !important; /* Ensure no border */
  box-shadow: none !important; /* Remove box shadow */
}

.pagination li.active a {
  color: #fff;
  border: none !important; /* Ensure no border */
  outline: none !important; /* Remove outline */
  box-shadow: none !important; /* Remove box shadow */
}

.pagination li:hover {
  background: #6A7587;
}

.pagination li:hover a {
  color: #fff;
}

.moreBtn a {
  height: 40px;
  width: 150px;
  color: #FFFFFF;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  display: inline-block;
  line-height: 40px;
  border-radius: 4px;
  background-color: #c4c8cf;
  border: none !important; /* Remove border */
  outline: none !important; /* Remove outline */
  box-shadow: none !important; /* Remove box shadow */
}

.moreBtn a:hover,
.moreBtn a.active {
  background: #6A7587;
}