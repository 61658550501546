.middle-wrapper {
    float: left;
    width: 100%;
    margin-top: 15px;
    padding: 0 15px;
    @media screen and (max-width:1440px){ 
        margin-top: 10px;
    }
    @media screen and (max-width:480px){
        padding: 0 10px;
    }
    &.pad0{
        padding:0px;
        margin:0px;      
    }
    .filterDropdown {
        position: absolute;
        width: 100%;
        padding: 10px 15px;
        background: #fff;
        border: 2px solid #E9E9E9;
        border-radius: 0 0 8px 8px;
        -webkit-border-radius: 0 0 8px 8px;
        -moz-border-radius: 0 0 8px 8px;
        border-top: none;
        z-index: 9999;
        top: 42px;

        li {
            display: block;

            a {
                color: #6C7789;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 37px;
                display: block;

                &:hover {
                    color: #7FB541;
                }
            }
        }
    }

    .main-title {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;

        @media screen and (max-width:1366px) {
            margin-bottom: 20px;
        }

        h1 {
            color: #262726;
            font-size: 1.938rem;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 32px;
            margin: 0;
            @media screen and (max-width:1440px){
                font-size:1.75rem;    
            }
            @media screen and (max-width:1366px){
                font-size:1.60rem;    
            }
            @media screen and (max-width:768px){
                font-size:1.3rem;    
            }
        }

        
        .rightAction-btn{
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
            gap:10px;  
            align-items: center;
            .addMore-btn{
                position: relative;
                a {
                    color: #6A7587;
                    font-size: 1rem;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 20px;
                    text-align: center;

                    span {
                        float: right;
                        margin-left:7px;                                          
                        img {
                            width:20px;
                            &.imgFirst {
                                display: inline-block;
                                opacity: 1;
                            }

                            &.imghover {
                                display: none;
                                opacity: 0;
                            }
                        }
                    }

                    &:hover,
                    &.active {
                        color: #6EB724;

                        span {
                            img {
                                &.imgFirst {
                                    display: none;
                                    opacity: 0;
                                }

                                &.imghover {
                                    display: inline-block;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
            .rightOption {
                float: left;
                width: 100%;
                text-align: right;
                margin: 0;
                align-items: center;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;

                li {
                    float: left;
                    margin-right: 5px;
                    position: relative;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -moz-flex;
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;                    
                    &.spaceDiv{
                        position:relative;                    
                        &:after{
                            position:absolute;
                            left:0px;
                            width:100%;
                            height:25px;
                            content:"";
                            bottom:-17px;
                        }
                    }    
                    &:last-child {
                        margin-right: 0px;
                    }

                    a {
                        display: inline-block;

                        span {
                            img {
                                &.imgFirst {
                                    display: inline-block;
                                    opacity: 1;
                                }

                                &.imghover {
                                    display: none;
                                    opacity: 0;
                                }
                            }
                        }

                        &:hover,
                        &.active {
                            span {
                                img {
                                    &.imgFirst {
                                        display: none;
                                        opacity: 0;
                                    }

                                    &.imghover {
                                        display: inline-block;
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }

                    .user-image-timeline {
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        top: 60px;
                        left: 0px;
                        width: 344px;
                        border-radius: 10px;
                        z-index: 9999;
                        transform: translate(-50%, -0%);
                        border: 1px solid #CDCFD3;
                        background: linear-gradient(0deg, #F5F5FA 0%, rgba(254, 254, 255, 0.99) 100%, #FFFFFF 100%);
                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                        -ms-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        transition: all 0.3s ease;

                        li {
                            width: 100%;

                            &.item-timeline {
                                &:nth-child(even) {
                                    &:before {
                                        position: absolute;
                                        top: -7px;
                                        left: 197px;
                                        display: inline-block;
                                        border-top: 1px solid #CDCFD3;
                                        border-right: 1px solid #CDCFD3;
                                        width: 13px;
                                        height: 13px;
                                        transform: rotate(-45deg);
                                        -webkit-transform: rotate(-45deg);
                                        -moz-transform: rotate(-45deg);
                                        background: white;
                                        z-index: 1;
                                        content: '';

                                        @media screen and (max-width:1600px) {
                                            width: 10px;
                                            height: 10px;
                                            top: -5px;
                                        }

                                        @media screen and (max-width:1140px) {
                                            width: 15px;
                                            height: 15px;
                                            top: -8px;
                                        }

                                        @media screen and (max-width:480px) {
                                            right: 90px;
                                        }
                                    }
                                }

                                &:nth-child(odd) {
                                    &:before {
                                        position: absolute;
                                        top: 50px;
                                        left: 8px;
                                        display: inline-block;
                                        border-top: 12px solid transparent;
                                        border-right: 12px solid #fff;
                                        border-bottom: 12px solid transparent;
                                        border-right-color: #808080;
                                        content: '';
                                    }
                                }
                            }

                            .inner-content {
                                margin: 0px;
                                padding: 20px;
                                float: left;
                                width: 100%;

                                .closeUpload {
                                    display: block;
                                    width: 26px;
                                    right: 9px;
                                    position: absolute;
                                    z-index: 99999;
                                    top: 9px;

                                    @media screen and (max-width:1600px) {
                                        width: 20px;
                                        right: 9px;
                                        top: 7px;
                                    }

                                    img {
                                        @media screen and (max-width:1600px) {
                                            width: 20px;
                                        }

                                        @media screen and (max-width:1280px) {
                                            width: 18px;
                                        }
                                    }
                                }

                                .imageDropper {
                                    border-radius: 0;
                                    float: left;
                                    border: 0px;
                                    text-align: center;
                                    margin: 0px 0;
                                    width: 100%;

                                    .dragDrop-img {
                                        width: 100%;
                                        float: left;
                                        position: relative;
                                        display: block !important;

                                        .drop-uploadSec {
                                            width: 100%;
                                            background: #fff;
                                            border: none;
                                            border-radius: 10px;
                                            height: auto;
                                            padding: 0;
                                            display: block !important;

                                            .dragImg-new {
                                                width: 100%;
                                                display: block;
                                                margin-top: 15px;

                                                @media screen and (max-width:1280px) {
                                                    margin-top: 10px;
                                                }

                                                img {
                                                    @media screen and (max-width:1600px) {
                                                        width: 90px;
                                                    }

                                                    @media screen and (max-width:1280px) {
                                                        width: 80px;
                                                    }
                                                }
                                            }

                                            .drop-uploadText {
                                                width: 100%;
                                                float: left;
                                                text-align: center;
                                                opacity: 1;
                                                line-height: 18px;
                                                border: 1px dashed #979797;
                                                border-radius: 8px;
                                                background-color: #FFFFFF;
                                                height: 118px;
                                                display: -webkit-box;
                                                display: -moz-box;
                                                display: -ms-flexbox;
                                                display: -moz-flex;
                                                display: -webkit-flex;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                color: #6C7789;
                                                font-size: 0.75rem;
                                                letter-spacing: 0;
                                                line-height: 19px;
                                                text-align: center;

                                                @media screen and (max-width:1440px) {
                                                    height: 98px;
                                                }
                                            }

                                            .drop-uploadBtn-Sec {
                                                width: 100%;
                                                float: left;
                                                text-align: center;

                                                ul {
                                                    li {
                                                        margin: 0px;
                                                        text-align: center;
                                                        width: 100%;
                                                        float: left;

                                                        .imageDropperNew {
                                                            margin: 15px 0px;
                                                            float: left;
                                                            width: 100%;

                                                            @media screen and (max-width:1440px) {
                                                                margin: 10px 0px;
                                                            }
                                                        }

                                                        a {
                                                            border: none;
                                                            width: 150px;
                                                            height: 40px;
                                                            padding: 0;
                                                            border-radius: 4px;
                                                            line-height: 40px;
                                                            background: #7FB541;

                                                            @media screen and (max-width:1440px) {
                                                                width: 140px;
                                                                height: 38px;
                                                            }

                                                            .dropText {
                                                                color: #FFFFFF;
                                                                font-size: 1rem;
                                                                font-weight: 600;
                                                                letter-spacing: 0;
                                                                line-height: 39px;
                                                                text-align: center;

                                                                @media screen and (max-width:1440px) {
                                                                    font-size: 0.9rem;
                                                                    line-height: 37px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .divider {
                                                float: left;
                                                width: 100%;
                                                margin-bottom: 20px;
                                                margin-top: 0px;

                                                span {
                                                    color: #6C7789;
                                                    font-size: 1.563rem;
                                                    letter-spacing: 0;
                                                    line-height: 32px;
                                                    text-align: center;
                                                    font-weight: 400;

                                                    @media screen and (max-width:1440px) {
                                                        font-size: 1.50rem;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    span {
                        margin: 0 6px;
                        display: inline-block;
                        color: #6A7587;
                        font-size: 0.875rem;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 19px;
                    }                    
                    &:hover, &.active {
                        .user-image-timeline {
                            opacity: 1;
                            visibility: visible;
                            -webkit-transition: all 0.3s ease;
                            -moz-transition: all 0.3s ease;
                            -ms-transition: all 0.3s ease;
                            -o-transition: all 0.3s ease;
                            transition: all 0.3s ease;                            
                        }
                        span {
                            img {
                                &.imgFirst {
                                    display: none;
                                    opacity: 0;
                                }

                                &.imghover {
                                    display: inline-block;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
            .toggleSwitches {
                position: relative;
                min-width: 75px;
                text-align: right;
                a {
                    color: #6A7587;
                    font-size: 1rem;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 20px;
                    text-align: right;
                    float: left;
                    margin-left: 15px;
                    span {
                        float: right;
                        margin-left: 7px;

                        img {
                            &.imgFirst {
                                display: inline-block;
                                opacity: 1;
                            }

                            &.imghover {
                                display: none;
                                opacity: 0;
                            }
                        }
                    }

                    &:hover,
                    &.active {
                        color: #6EB724;

                        span {
                            img {
                                &.imgFirst {
                                    display: none;
                                    opacity: 0;
                                }

                                &.imghover {
                                    display: inline-block;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }    
    }
    .protein-top-header{
        float:left;
        width:100%;        
        display: flex;
        gap: 15px;
        align-items: center;
        .protein-search{
            position:relative;
            float:left;
            width:100%;
            max-width:400px;
            input[type="text"]{                    
                height: 46px;
                color: #707070;
                border-radius: 8px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;    
                padding: 5px 110px 5px 15px;            
                @media screen and (max-width:1440px) {
                    height: 44px;
                    padding: 5px 10px;
                } 
            }    
            .btn-search{
                position: absolute;
                top: 0;
                right: 0;
                color: #fff;
                font-size: 1rem;
                letter-spacing: 0;
                line-height: 29px;
                text-align: center;
                display: inline-block;
                padding:5px 15px 5px 15px;                    
                border-radius: 4px;
                width: 100px;
                background: #7FB541;
                text-align:center;
                height: 46px;
                border: none;                
                border-radius: 0 8px 8px 0;
                -webkit-border-radius: 0 8px 8px 0;
                -moz-border-radius: 0 8px 8px 0;
                @media screen and (max-width:1440px) {                    
                    height:44px;  
                }
            }
        }
        .totalResults {            
            @media screen and (max-width:480px){
                position: absolute;
                bottom: 6px;
                right: 0;    
            }
            span {
                color: #262726;
                font-size: 1.563rem;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 32px;

                @media screen and (max-width:1440px) {
                    font-size: 1.375rem;
                }

                @media screen and (max-width:1366px) {
                    font-size: 1.25rem;
                }
            }
        }
    }
    .upload-container-block{
        float:left;
        width:100%;
        margin-bottom: 20px;
        .user-image-timeline {
            padding: 0;
            margin: 0;
            list-style: none;
            float: left;
            width: 100%;
            border-radius: 10px;
            z-index: 9999;            
            border: 1px solid #CDCFD3;
            background: linear-gradient(0deg, #F5F5FA 0%, rgba(254, 254, 255, 0.99) 100%, #FFFFFF 100%);
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
            li {
                width: 100%;
                &.item-timeline {
                    &:nth-child(even) {
                        &:before {
                            position: absolute;
                            top: -7px;
                            left: 167px;
                            display: inline-block;
                            border-top: 1px solid #CDCFD3;
                            border-right: 1px solid #CDCFD3;
                            width: 13px;
                            height: 13px;
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            background: white;
                            z-index: 1;
                            content: '';

                            @media screen and (max-width:1600px) {
                                width: 10px;
                                height: 10px;
                                top: -5px;
                            }

                            @media screen and (max-width:1140px) {
                                width: 15px;
                                height: 15px;
                                top: -8px;
                            }

                            @media screen and (max-width:480px) {
                                right: 90px;
                            }
                        }
                    }

                    &:nth-child(odd) {
                        &:before {
                            position: absolute;
                            top: 50px;
                            left: 8px;
                            display: inline-block;
                            border-top: 12px solid transparent;
                            border-right: 12px solid #fff;
                            border-bottom: 12px solid transparent;
                            border-right-color: #808080;
                            content: '';
                        }
                    }
                }

                .inner-content {
                    margin: 0px;
                    padding: 20px;
                    float: left;
                    width: 100%;

                    .closeUpload {
                        display: block;
                        width: 26px;
                        right: 9px;
                        position: absolute;
                        z-index: 99999;
                        top: 9px;

                        @media screen and (max-width:1600px) {
                            width: 20px;
                            right: 9px;
                            top: 7px;
                        }

                        img {
                            @media screen and (max-width:1600px) {
                                width: 20px;
                            }

                            @media screen and (max-width:1280px) {
                                width: 18px;
                            }
                        }
                    }

                    .imageDropper {
                        border-radius: 0;
                        float: left;
                        border: 0px;
                        text-align: center;
                        margin: 0px 0;
                        width: 100%;

                        .dragDrop-img {
                            width: 100%;
                            float: left;
                            position: relative;
                            display: block !important;

                            .drop-uploadSec {
                                width: 100%;                                
                                border: none;
                                border-radius: 10px;
                                height: auto;
                                padding: 0;
                                display: block !important;

                                .dragImg-new {
                                    width: 100%;
                                    display: block;
                                    margin-top: 15px;

                                    @media screen and (max-width:1280px) {
                                        margin-top: 10px;
                                    }

                                    img {
                                        @media screen and (max-width:1600px) {
                                            width: 90px;
                                        }

                                        @media screen and (max-width:1280px) {
                                            width: 80px;
                                        }
                                    }
                                }

                                .drop-uploadText {
                                    width: 100%;
                                    float: left;
                                    text-align: center;
                                    opacity: 1;
                                    line-height: 18px;
                                    border: 1px dashed #979797;
                                    border-radius: 8px;
                                    background-color: #FFFFFF;
                                    height: 118px;
                                    display: -webkit-box;
                                    display: -moz-box;
                                    display: -ms-flexbox;
                                    display: -moz-flex;
                                    display: -webkit-flex;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: #6C7789;
                                    font-size: 0.75rem;
                                    letter-spacing: 0;
                                    line-height: 19px;
                                    text-align: center;

                                    @media screen and (max-width:1440px) {
                                        height: 98px;
                                    }
                                }
                                .file-error{
                                    float: left;
                                    width: 100%;
                                    margin: 15px 0 0 0;
                                    font-size: 16px; 
                                }
                                .drop-uploadBtn-Sec {
                                    width: auto;
                                    text-align: center;
                                    display: inline-block;

                                    ul {
                                        li {
                                            margin: 0px;
                                            text-align: center;
                                            width: 100%;
                                            float: left;

                                            .imageDropperNew {
                                                margin:0px 0px;
                                                float: left;
                                                width: 100%;
                                            }

                                            a {
                                                border: none;
                                                width: 150px;
                                                height: 40px;
                                                padding: 0;
                                                border-radius: 4px;
                                                line-height: 40px;
                                                background: #7FB541;

                                                @media screen and (max-width:1440px) {
                                                    width: 140px;
                                                    height: 38px;
                                                }

                                                .dropText {
                                                    color: #fff;
                                                    font-size: 1rem;
                                                    font-weight: 600;
                                                    letter-spacing: 0;
                                                    line-height: 39px;
                                                    text-align: center;
                                                    background: #7FB541;
                                                    padding: 3px 27px;
                                                    border-radius: 5px;
                                                    @media screen and (max-width:1440px) {
                                                        font-size: 0.9rem;
                                                        line-height: 37px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .divider {
                                    float: left;
                                    width: 100%;
                                    margin-bottom: 20px;
                                    margin-top: 0px;

                                    span {
                                        color: #6C7789;
                                        font-size: 1.563rem;
                                        letter-spacing: 0;
                                        line-height: 32px;
                                        text-align: center;
                                        font-weight: 400;

                                        @media screen and (max-width:1440px) {
                                            font-size: 1.50rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }    
    .dashboard-bar {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: 0px; 
        margin-bottom: 40px;
        @media screen and (max-width:1023px){
            gap:20px;     
        }
        &.showBar {
            display: none;
        }
 
        .blockSection {
            flex: 0 23.3%;
            border-radius: 8px;
            background-color: #F0F4FF;
            cursor: pointer;
            @media screen and (max-width:1023px){
                flex: 0 48.5%;
            }
            @media screen and (max-width:480px){
                flex: 0 100%;
            }
            &.active {
                box-shadow: 0px 0px 0px 2px #5877cb inset;
                -webkit-box-shadow: 0px 0px 0px 2px #5877cb inset;
                -moz-box-shadow: 0px 0px 0px 2px #5877cb inset;
            }

            .block-first {
                position: relative;
                padding: 20px;
                float: left;
                width: 100%;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                align-items: center;
                justify-content: start;
                height: 120px;
                @media screen and (max-width:1440px){
                    height: 110px;
                    padding: 15px 18px;
                }
                .iconSet {
                    position: absolute;
                    left: 19px;
                    top: 26px;
                    width: 42px;
                    height: 42px;
                    background: #004CFF;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -moz-flex;
                    display: -webkit-flex;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                }

                .count {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    padding: 5px 9px;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -moz-flex;
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;
                    line-height: 12px;

                    span {
                        margin-right: 3px;
                        line-height: 5px;
                    }

                    label {
                        font-size: 0.625rem;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 13px;
                        color: #FB745C;
                    }
                }

                .blockHead {
                    padding: 0px 0 0px 57px;
                    margin-top: -6px;

                    span {
                        color: #6C7789;
                        font-size: 1rem;
                        letter-spacing: 0;
                        line-height: 20px;
                        display: block;
                        @media screen and (max-width:1440px){
                            font-size:0.938rem;
                        }
                    }

                    h2 {
                        color: #262726;
                        display: block;
                        font-size: 2.438rem;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 40px;
                        margin: 3px 0 0 0;
                        @media screen and (max-width:1440px){
                            font-size:2.25rem;    
                            line-height:36px;
                        }
                        @media screen and (max-width:1366px){
                            font-size:2rem;    
                            line-height:30px;
                        }
                    }
                }
            }

            &.researchPending {
                background-color: #F8F6FF;
                &.active {
                    box-shadow: 0px 0px 0px 2px #9e82ff inset;
                    -webkit-box-shadow: 0px 0px 0px 2px #9e82ff inset;
                    -moz-box-shadow: 0px 0px 0px 2px #9e82ff inset;
                }
    
                .block-first {
                    .iconSet {
                        background: #9279FF;
                    }

                    .count {
                        label {
                            color: #6EB724;
                        }
                    }
                }
            }

            &.researchComplete {
                background-color: #F8FEEB;
                &.active {
                    box-shadow: 0px 0px 0px 2px #a1c15b inset;
                    -webkit-box-shadow: 0px 0px 0px 2px #a1c15b inset;
                    -moz-box-shadow: 0px 0px 0px 2px #a1c15b inset;
                }
    
                .block-first {
                    .iconSet {
                        background: #6EB724;
                    }

                    .count {
                        label {
                            color: #6EB724;
                        }
                    }
                }
            }

            &.reworkPending {
                background-color: #FFF3F6;
                &.active {
                    box-shadow: 0px 0px 0px 2px #EB557B inset;
                    -webkit-box-shadow: 0px 0px 0px 2px #EB557B inset;
                    -moz-box-shadow: 0px 0px 0px 2px #EB557B inset;
                }
    
                .block-first {
                    .iconSet {
                        background: #EB557B;
                    }

                    .count {
                        label {
                            color: #FB745C;
                        }
                    }
                }
            }
        }
    }
}

.showHideSection{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content:space-between;
    margin-bottom:0px;    
    @media screen and (max-width:991px){
        margin-top:30px;    
    }   
    .small-title {
        float: left;
        width: 100%;
        margin-bottom:0px;
        h2 {
            color: #262726;
            margin: 0px;
            font-size: 1.25rem;
            font-weight: bold;
            letter-spacing: 0.3px;
            line-height: 24px;
            display: inline-block;
            margin-right: 5px;
            @media screen and (max-width:1440px) {
                font-size: 1.15rem;
            }
            @media screen and (max-width:1366px) {
                font-size: 1.10rem;
            }
        }

        .addBtn {
            display: inline-block;

            span {
                img {
                    width:42px;   
                    @media screen and (max-width:1440px) {
                        width:32px;   
                    }
                    &.imgFirst {
                        display: inline-block;
                        opacity: 1;
                    }

                    &.imghover {
                        display: none;
                        opacity: 0;
                    }
                }
            }

            &:hover, &.active{
                span {
                    img {
                        &.imgFirst {
                            display: none;
                            opacity: 0;
                        }

                        &.imghover {
                            display: inline-block;
                            opacity: 1;
                        }
                    }
                }
            }

        }
    }    
}
.bulkUpload-header{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    position:relative;
    @media screen and (max-width:1366px) {
        margin-bottom: 20px;
    }
    .upload-title{
        padding:0px;
        h1 {
            color: #262726;
            font-size: 1.938rem;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 32px;
            margin: 0;
            @media screen and (max-width:1440px){
                font-size:1.75rem;    
            }
            @media screen and (max-width:1366px){
                font-size:1.60rem;    
            }
            @media screen and (max-width:768px){
                font-size:1.3rem;    
            }
        }        
    }
    .showRecord-top{        
        font-size:24px;
        font-weight:600;
        letter-spacing: 0;
        line-height: 32px;
        margin: 0;
        position: absolute;
        left: 50%;
        top:0;
        transform: translate(-50%, 0px);
        -webkit-transform: translate(-50%, 0px);
        -moz-transform: translate(-50%, 0px);
        @media screen and (max-width:1440px){
            font-size:20px;  
        }
        @media screen and (max-width:1366px){
            font-size:18px;  
        }
        @media screen and (max-width:768px){
            font-size:16px;  
        }
        &.successMsz{
            color:#6EB724;   
        }
        &.errorUploadMsz{
            color:#D41C27;   
        }
    }
    .upload-template{
        position: relative;
        min-width: 75px;
        text-align: right;
        a {
            color: #6A7587;
            font-size: 1rem;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;
            text-align: right;
            float: left;
            margin-left: 15px;
            span {
                float: left;
                margin-right: 7px;

                img {
                    &.imgFirst {
                        display: inline-block;
                        opacity: 1;
                    }

                    &.imghover {
                        display: none;
                        opacity: 0;
                    }
                }
            }

            &:hover,
            &.active {
                color: #6EB724;

                span {
                    img {
                        &.imgFirst {
                            display: none;
                            opacity: 0;
                        }

                        &.imghover {
                            display: inline-block;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}        
.import-data-grid{
    float:left;
    width:100%;
    .table-wrapper{
        .table-formating{
            tbody{
                tr{
                    td{
                        &:first-child{
                            text-align:left;
                        }
                        &.input-error-td{
                            width:250px;                        
                        }
                        &.select-error-td{
                            width:250px;                        
                        }
                    }
                } 
            } 
        } 
    } 
    .uploadResult-Container{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        flex-flow: wrap;
        align-items: baseline;
        justify-content: space-between;
        margin-top: 0px;
        margin-bottom: 35px;  
        @media screen and (max-width:480px){
            display: block;
            float: left;
            width: 100%;
        }
        .upload-block-left{
            padding: 0px;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            gap:20px;
            @media screen and (max-width:480px){
                display:block; 
                position:relative;    
            }
            .upload-Results{                
                padding:0px;             
                span {
                    color: #262726;
                    font-size: 1.563rem;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 32px;

                    @media screen and (max-width:1440px) {
                        font-size: 1.375rem;
                    }

                    @media screen and (max-width:1366px) {
                        font-size: 1.25rem;
                    }
                }
            }
            .upload-btn-set{
                padding:0px;
                .btn-big{
                    font-size: 1rem;
                    letter-spacing: 0;
                    line-height: 8px;
                    text-align: center;
                    padding: 2px 10px;
                    height: 40px;
                    border-radius: 4px;
                    background-color: #7FB541;
                    color: #fff;
                    border: none;
                    min-width: 100px;
                    margin-right: 10px;
                }                
            }
        }            
        .import-total-record{
            padding-right: 0px;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;          
            gap:15px;
            .firstDiv{
                padding:0px;
                .errorFirst{ 
                    display: block;
                    font-size: 16px;
                    line-height: 18px;
                    border: 1px solid #7FB541;
                    color: #7FB541;
                    padding: 7px 10px;
                    font-weight: 400;
                    border-radius: 3px;
                    margin-bottom: 5px;
                    cursor: pointer;
                    text-align: center;                    
                }
                .errorSecond{ 
                    display: block;
                    font-size: 14px;
                    line-height: 14px;
                    border: 1px solid #6A7587;
                    color: #6A7587;
                    padding: 7px 10px;
                    font-weight: 400;
                    border-radius: 3px;                    
                    cursor: pointer;
                    text-align: center;                    
                }            
            }
            .secondDiv{
                font-size: 14px;
                padding: 0 20px;
                font-weight: 400;
                color: #fff;
                border-radius: 5px;
                background: #D41C27;
                line-height: 14px;
                display: flex;
                align-items: center;
            }
        }
    }    
}


.productInfoDiv{                                
    margin-bottom:10px;
    display: flex;
    flex-flow: wrap;
    width: 100%;
    .smallBox-inner{        
        width:100%; 
        float:left;                                       
        position:relative;    
        .product-Image {
            width: 200px;
            height: 120px;
            margin-right: 20px;
            border-radius: 8px;
            position: absolute;
            left: 0;
            top: 0;
            img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }
        }
        .product-desc{
            width:100%;
            float:left;
            padding-left:220px;  
            min-height:140px;              
            h6{
                margin-bottom: 4px;
                font-weight: 400;
                font-size: 20px;
                line-height: 26px;
                color: #000000;
                position:relative;   
                .unlinkSet{
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                    color: #666666;
                    display: inline-block;
                    width: auto;
                    position: absolute;
                    right: 0;
                    top: 0;
                    border: 1px solid #464646;
                    padding: 2px 10px;
                    border-radius: 50px;
                    height: 28px;
                    line-height: 22px;
                    min-width: 70px;
                    text-align: center;
                    &:hover{
                        background: #7FB541;
                        color: #fff;
                        border-color:#7FB541;
                    }
                } 
            }
            span{
                font-weight: 400;
                font-size: 14px;
                line-height:20px;
                color: #666666;
                display: inline-block;
                width: 100%;
            }
            strong {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
            }
        }
    }                                
}