.customModal{
    float: left;
    width: 100%;
    /* height: 100vh; */
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity:0;
    visibility: hidden;
    transition: 0.25s;
    z-index:9999;
    &.modalOpen{
        opacity: 1;
        visibility: visible;
        transition: 0.25s;
    }
    #sliderMain{
        margin-left:0px;
        margin-top: 0px;  
        width:752px;
        margin:100px auto 0;
        @media screen and (max-width:1600px){ 
            width:680px;
            margin:80px auto 0;
        }
        @media screen and (max-width:1440px){ 
            width:650px;
            margin:70px auto 0;
        }
        @media screen and (max-width:1366px){ 
            width:500px;    
            margin:50px auto 0;
        }
    }
    .slider{
        .carousel.carousel-slider{
            width:100%!important;
            overflow: visible;
        }
        .carousel .slide{
            height: 100%!important;
            width:100%!important;
        }
        .carousel.carousel-slider .control-arrow:hover{
            background:none;
        }    
        .carousel.carousel-slider .control-arrow{
            width: 40px;
            height: 40px;
            padding: 0px; 
            left:auto;
            top: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            border: 1px solid #606060;
            border-radius: 50%;
        } 
        .carousel .control-prev.control-arrow{
            left: -42px;
        }       
        .carousel .control-next.control-arrow{
            right: -82px;
        }                    
        .control-dots{
            display:none!important; 
        }
        .carousel .thumbs-wrapper {
            margin: 25px 0 0 0!important;   
            @media screen and (max-width:1440px){ 
                margin:20px 0 0 0!important;   
            }                 
        }
        .carousel .thumb.selected, .carousel .thumb:hover{
            border:none!important;  
        }
        .carousel .thumb{
            border: none !important;
            padding: 0px !important;                    
            border-radius: 10px;
            //height:125px;
            /*@media screen and (max-width:1440px){ 
                width: 147px!important;
                height:110px;
            }
            @media screen and (max-width:1366px){ 
                width: 135px!important;
                height:101px;
            }*/
        }
        .carousel .thumb:focus{
            border:none!important  
        }
        .carousel .slide img{
            border-radius: 10px;
        }
        .carousel .control-prev.control-arrow:before{
            border:none;
            position: absolute;
            content: "";
            top:50%;
            left:50%;
            margin:0px;
            height:18px;
            width:12px;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            background: url(../assets/images/previous_icon.png) no-repeat;    
            background-position: 0px 0px;                        
        }

        .carousel .control-next.control-arrow:before{
            border:none;
            position: absolute;
            content: "";
            top:50%;
            height:18px;
            width: 12px;
            margin:0px;
            background: url(../assets/images/next_icon.png) no-repeat;    
            background-position: 2px 0px;     
            left:50%;               
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);            
        }
        .carousel .control-arrow{
            border: 1px solid #606060;
            border-radius: 50%;
            width: 40px;
            height: 40px;
        }
        .carousel .thumbs-wrapper .control-prev.control-arrow{
            left: -62px;
            top: 55px;
            @media screen and (max-width:1440px){ 
                top:50px;
            }
            @media screen and (max-width:1366px){ 
                top:43px;
            }
        }       
        .carousel .thumbs-wrapper .control-next.control-arrow{
            right: -62px;
            top: 55px;
            @media screen and (max-width:1440px){ 
                top:50px;
            }
            @media screen and (max-width:1366px){ 
                top:43px;
            }
        } 
        .carousel{
            .carousel-status {
                right:15px;
                text-shadow: 1px 1px 1px rgb(0 0 0 / 90%);
                margin: 0;
                top: 5px;
            }
        }
    }    
    .tagsHeader{
        position: absolute;
        top: 0;
        z-index: 9999;
        width: 100%;    
        .tagsStar{
            position: absolute;
            left:15px;
            top:15px;  
            cursor:pointer;      
            img{
                width:30px;            
                &.imgFirst{  
                    display:inline-block;
                    opacity:1;
                }
                &.imgHover{
                    display:none;
                    opacity:0;
                }  
            }    
            &:hover{
                img{
                    &.imgFirst{                          
                        display:none;
                        opacity:0;
                    }
                    &.imgHover{
                        display:inline-block;
                        opacity:1;
                    }
                }
            }
            &.active{
                img{
                    &.imgFirst{                          
                        display:none;
                        opacity:0;
                    }
                    &.imgHover{
                        display:inline-block;
                        opacity:1;
                    }
                }
            }
        }
        .tagsClose{
            position: absolute;
            right:15px;
            top: 15px;  
            cursor:pointer;     
            img{
                width:30px;            
                &.imgFirst{  
                    display:inline-block;
                    opacity:1;
                }
                &.imgHover{
                    display:none;
                    opacity:0;
                }  
            }    
            &:hover{
                img{
                    &.imgFirst{                          
                        display:none;
                        opacity:0;
                    }
                    &.imgHover{
                        display:inline-block;
                        opacity:1;
                    }
                }
            }
            &.active{
                img{
                    &.imgFirst{                          
                        display:none;
                        opacity:0;
                    }
                    &.imgHover{
                        display:inline-block;
                        opacity:1;
                    }
                }
            }
        }
    }         
    .tagsSet{
        position: absolute;
        bottom:25px;
        right:30px;    
        a{
            color: #EB557B;  
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            margin-right: 10px;        
            &:last-child{
                margin-right:0px;
            }
            &.defaultBtn{
                color: #FFFFFF;
                height: 28px;
                display:inline-block;       
                width: 93px;
                font-family: 'PT Sans';
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0;
                border-radius: 8px;
                background-color: #7FB541;
                line-height:28px;
            }
        }
    }
}    

.closeBtn{
    width: 40px;
    height: 40px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 99999;
    cursor: pointer;
    img{
        width:100%;
    }
}        
.fixedBody{
    overflow: hidden;
}