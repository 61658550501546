.checkbox label {
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  color: #262726;
  line-height: 19px;
  margin: 0;
}

.checkbox label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(108, 119, 137, 0.5);
  background-color: #fff;
  float: left;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 8px; /* Add some space between checkbox and text */
}

.checkbox label:after {
  display: inline-block;
  width: 20px;
  line-height: 23px;
  cursor: pointer;
  height: 20px;
  float: left;
  border: 1px solid rgba(108, 119, 137, 0.5);
  border-radius: 4px;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.checkbox input[type="checkbox"]:checked + label:after {
  background: #96BE4F url('../assets/images/tick-white.svg') no-repeat;
  content: "";
  border-color: #96BE4F;
  background-position: center;
  background-size: 16px;
}

.checkbox input[type="checkbox"]:checked + label:before {
  display: none;
}

.checkbox label.block {
  display: block;
  font-weight: bold;
  color: #000000; /* Set color to black */
}
