.pageNot-Found {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    h3 {
        color: #262726;
        font-size: 39px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 51px;
        display: block;
        margin-bottom: 20px;
    }

    a {
        height: 40px;
        width: 150px;
        border-radius: 4px;
        background-color: #7FB541;
        color: #FFFFFF;
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: 0;
        text-align: center;
        display: inline-block;
        line-height: 39px;

        img {
            display: inline-block;
            margin-top: -1px;
        }
    }
}

.page404-image {
    position: fixed;
    bottom: 0;
    left: 50%;
    pointer-events: none;
    z-index: -1;
    width: 100%;
    text-align: center;
    max-width: 900px;
    transform: translate(-50%, 0px);
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0px);

    img {
        width: 100%;
    }
}