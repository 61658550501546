/* src/styles/global.css */
html, body {
    height: 100%;
    margin: 0;
  }
  
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1;
  }
  
  .footer {
   
    background-color: #f8f9fa;
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid #e7e7e7;
    
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer p {
    margin: 0;
    color: #6c757d;
  }
  
  .footer-nav {
    margin-top: 10px;
  }
  
  .footer-nav a {
    margin: 0 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .footer-nav a:hover {
    text-decoration: underline;
  }
  