// _dropdown.scss

.react-select__control {
    border-radius: 9999px;  // Equivalent to Tailwind's rounded-full
    border: 1px solid #D1D5DB;  // Equivalent to Tailwind's border-gray-300
    padding: 0.25rem;  // Adjust padding if necessary
    width: 100%;  // Full width
    box-shadow: none;  // Remove default box-shadow
  }
  
  .react-select__control:hover {
    border-color: #A0AEC0;  // Optional: Change border color on hover
  }
  
  .react-select__multi-value {
    border-radius: 9999px;  // Apply rounded-full to selected items
  }
  
  .react-select__multi-value__label {
    border-radius: 9999px;  // Apply rounded-full to the label of the selected items
  }
  
  .react-select__multi-value__remove {
    border-radius: 9999px;  // Apply rounded-full to the remove icon of the selected items
  }
  