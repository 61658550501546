@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* @font-face {
  font-family: 'PT Sans';
  src: local('PT Sans'),
    url('./assets/fonts/PT_Sans/PTSans-Regular.ttf') format('truetype'),
    url('./assets/fonts/PT_Sans/PTSans-Italic.ttf') format('truetype'),
    url('./assets/fonts/PT_Sans/PTSans-Bold.ttf') format('truetype'),
    url('./assets/fonts/PT_Sans/PTSans-BoldItalic.ttf') format('truetype');
} */

body {
  font-family: 'PT Sans', sans-serif;
  color: #262726;
  font-weight: 400;
  font-size: 14px;
  padding: 0;
  margin: 0;
  line-height: 18px;
}